import DatePicker from "react-datepicker";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {formatTimeslotTimeRange} from "../../lib/purchase/timeslot";
import {parseISO} from "date-fns";
import equalWidth from "../../equalWidth";

const WrappedCustomInput = React.forwardRef((props, ref) => {
  return <CustomInput {...props} forwardedRef={ref}/>;
});

const CustomInput = ({value, onClick, disabled}) => (
  <Button onClick={onClick} className={value ? '' : 'btn btn-small'} className="form-control" disabled={disabled}>
    {value || 'Select date'}
  </Button>
);

const Button = styled.button`
  white-space: nowrap;
  appearance: none;
  border: none;
  margin: 0;
  //color: inherit;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ControlWrapper = styled.div`
  display: flex;
`;

const Select = styled.select`
  width: 120px;
  margin-left: 1em;
`;

const timeslotsToDatesAndTimes = (timeslots, timeslot_valid_since, timeslot_valid_until, timeFormat) => {
  const validSinceTime = timeslot_valid_since ? parseISO(timeslot_valid_since + 'Z') : null;
  const validUntilTime = timeslot_valid_until ? parseISO(timeslot_valid_until + 'Z') : null;

  return timeslots
    .filter(({valid}) => valid)
    .filter(({timeslotDate, timeslotStart, timeslotEnd}) => {
      const timeslotStartTime = parseISO(`${timeslotDate}T${timeslotStart}Z`)
      const timeslotEndTime = parseISO(`${timeslotDate}T${timeslotEnd}Z`)
      if (validSinceTime && timeslotStartTime < validSinceTime) {
        return false;
      }
      if (validUntilTime && timeslotEndTime > validUntilTime) {
        return false;
      }
      return true;
    })
    .reduce((acc, timeslot) => {
      const {timeslotDate, timeslotStart, timeslotEnd, leftUsages, id} = timeslot;

      return {
        ...acc,
        [timeslotDate]: [
          ...(acc[timeslotDate] || []),
          {
            id,
            label: formatTimeslotTimeRange(timeslot, timeFormat),
            // discounts: timeslot.discounts,
            leftUsages,
          }
        ]
      };
    }, {});
}

const stringToDate = (s) => {
  return s ? new Date(s + 'T00:00:00') : null;
}

const dateToString = (date) => {
  if (date instanceof Date) {
    return [("" + date.getFullYear()).padStart(4, "0"), ("" + (date.getMonth() + 1)).padStart(2, "0"), ("" + date.getDate()).padStart(2, "0")].join("-");
  } else {
    return date;
  }
}

const getGroupDates = (dateTimes) => {
  return dateTimes ? Object.keys(dateTimes) : [];
}

function getDates(dateAndTimes, timeslot) {
  let dates = getGroupDates(dateAndTimes).map(stringToDate);
  if (timeslot) {
    dates = [...new Set([...dates, stringToDate(timeslot.timeslotDate)])];
  }
  return dates;
}

function getTimesForDate(dateAndTimes, selectedDate, timeslot, timeFormat) {
  if (selectedDate) {
    const selectedDateString = dateToString(selectedDate);
    const times = dateAndTimes[selectedDateString];
    if (times != null) {
      if (timeslot != null && timeslot.id != null
        && selectedDateString == timeslot.timeslotDate
        && times.find(time => time.id == timeslot.id) === undefined) {
        return [
          {
            id: timeslot.id,
            label: formatTimeslotTimeRange(timeslot, timeFormat),
            // discounts: timeslot.discounts,
            leftUsages: null,
          },
          ...times,
        ]
      } else {
        return times;
      }
      // return (times || []).map(({label, id, leftUsages}) => {
      //   return (
      //     <option value={id} key={id}
      //             disabled={leftUsages !== null && left_usages <= 0}>
      //       {label}
      //     </option>
      //   )
      // });
    } else if (timeslot) {
      return [
        {
          id: timeslot.id,
          label: formatTimeslotTimeRange(timeslot, timeFormat),
          // discounts: timeslot.discounts,
          leftUsages: null,
        }
      ]
      // return (
      //   <option value={timeslot.id} key={timeslot.id}>
      //     {formatTimeslotTimeRange(timeslot)}
      //   </option>
      // )
    } else {
      return [];
    }
  } else {
    return [];
  }
}

export function TimeslotControl({onChange, timeslot, timeslotGroup, timeFormat, numUsages}) {

  const dateAndTimes = timeslotGroup ? timeslotsToDatesAndTimes(timeslotGroup.timeslots, null, null, timeFormat) : []
  // const [times, setTimes] = useState([]);
  // const [selectedDate, setSelectedDate] = useState(timeslot && stringToDate(timeslot.timeslotDate));

  const dates = getDates(dateAndTimes, timeslot);


  const getTimes = () => {
    return getTimesForDate(dateAndTimes, getSelectedDate(), timeslot, timeFormat);
  }

  const onDateChange = (date) => {
    // setSelectedDate(date);
    onChange({
      id: null,
      timeslotDate: dateToString(date),
      timeslotStart: null,
      timeslotEnd: null,
      leftUsages: null,
      datetime: null,
    });
  }

  const getSelectedDate = () => {
    return timeslot && stringToDate(timeslot.timeslotDate);
  }

  const onTimeChange = (event) => {
    const timeslotId = event.target.value ? parseInt(event.target.value) : null;
    const newTimeslot = timeslotId ? (timeslotId == timeslot.id ? timeslot : (timeslotGroup.timeslots.find(({id}) => id == timeslotId))) : null;
    onChange(newTimeslot);
  }

  const times = getTimes();

  const disabled = numUsages === 0;

  return <Wrapper>
    {/*<div className="mb-1 small">rebook {numUsages} {numUsages == 1 ? "ticket" : "tickets"}:</div>*/}
    <ControlWrapper>
      <DatePicker
        includeDates={dates}
        wrapperClassName="" popperPlacement="bottom-center"
        onChange={onDateChange}
        dateFormat="yyyy-MM-dd"
        selected={getSelectedDate()}
        customInput={<WrappedCustomInput disabled={disabled}/>}
        disabled={disabled}/>
      <Select value={timeslot.id || ""}
              onChange={onTimeChange}
              className="form-control"
              disabled={disabled}>
        {times.length > 0 ?
          <React.Fragment>
            <option value={""} key="">Select</option>
            {times.map(({id, label, leftUsages}) => {
              return (
                <option value={id} key={id} disabled={id!=timeslot.id && numUsages!==null && leftUsages!==null && numUsages > leftUsages}>
                  {label}{leftUsages!==null && (leftUsages < 10 || numUsages > leftUsages) ?  (" - " + leftUsages + " left") : ""}
                </option>
              );
            })}
          </React.Fragment>
          :
          <option value={""} key="">No timeslots</option>
        }
      </Select>
    </ControlWrapper>
  </Wrapper>;
}