import React from "react";
import * as Yup from "yup";
import DataField from "./controls/DataField";
import DataFieldArray from "./controls/DataFieldArray";
import DataForm from "./controls/DataForm";

const BranchDataEditor = ({ value, onDataReset, onDataSubmit, storagePath }) => {
  const defaultValues = {};

  const expectedFiles = [];

  const validationSchema = Yup.object({
    branchName: Yup.string().required("Required"),
    backFields: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required("Required"),
        value: Yup.string().required("Required"),
        label: Yup.string().required("Required"),
        attributedValue: Yup.string().optional(),
      })
    ),
  });

  return (
    <DataForm
      value={value}
      onDataReset={onDataReset}
      onDataSubmit={onDataSubmit}
      storagePath={storagePath}
      defaultValues={defaultValues}
      expectedFiles={expectedFiles}
      validationSchema={validationSchema}
    >
      {(props) => (
        <React.Fragment>
          <DataField
            field="branchName"
            type="text"
            label="Branch Name"
            placeholder=""
          />
          <DataFieldArray
            modelSingular="Field"
            modelPlural="Back Fields"
            field="backFields"
            values={props.values}
            newObject={{
              key: "",
              value: "",
              label: "",
            }}
          >
            {({ prefix }) => (
              <React.Fragment>
                <div className="row mb-1">
                  <div className="col-12">
                    <DataField
                      field={`${prefix}key`}
                      type="text"
                      label="Key"
                      placeholder="Unique key"
                    />
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-12">
                    <DataField
                      field={`${prefix}label`}
                      type="text"
                      label="Label"
                      placeholder="Label"
                    />
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-12">
                    <DataField
                      field={`${prefix}value`}
                      type="text"
                      label="Value"
                      placeholder="Field value"
                    />
                  </div>
                </div>

                <div className="row mb-1">
                  <div className="col-12">
                    <DataField
                      field={`${prefix}attributedValue`}
                      type="text"
                      label="Attributed value"
                      placeholder="If set, overrides Value. Can include <a> HTML tag"

                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </DataFieldArray>
        </React.Fragment>
      )}
    </DataForm>
  );
};

export default BranchDataEditor;
