import React from "react";
import equalWidth from "../equalWidth";

import WalletPassDataEditorApp from "../containers/walletPass/WalletPassDataEditorApp";
import { initReactApp } from "../lib/reactApp";



export default {
  initForm() {
    const appName = 'walletPassDataEditorApp';
    const appInput= document.querySelector(`[data-editor="walletPassDataEditor"]`);
    if (appInput) {
      let appContainer = document.querySelector(`[data-app-container="${appName}"]`);
      if (!appContainer) {
        const element = document.createElement('div');
        for (let key in appInput.dataset) {
          element.dataset[key] = appInput.dataset[key];
        }
        element.setAttribute('data-app-container', appName);
        appInput.parentNode.appendChild(element);
        appContainer = document.querySelector(`[data-app-container="${appName}"]`);
      }

      initReactApp('walletPassDataEditorApp', (dataset) => {
        return <WalletPassDataEditorApp {...dataset} value={JSON.parse(appInput.value)}/>
      });
    }

  },
}
