export default {
  init() {
    $('#payments-table').on('click', '.create-receipt', (e) => {
      e.preventDefault()
      let id = $(e.target).attr('data-rel')

      this.createReceipt(id, (result) => {
        if (result == "Error") {
          alert("Something went wrong")
        } else {
          window.location.reload()
        }
      })
    })
    .on('click', '.send-receipt', (e) => {
      e.preventDefault()
      let id = $(e.target).attr('data-rel')
      let email = $(e.target).attr('data-email')

      email = window.prompt("Email to", email);

      if (email!==null) {
        this.sendReceipt(id, email, (message) => {
          alert(message)
        })
      }
    })
  },

  sendReceipt(id, email, callback) {
    let token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      url: `/payments/${id}/send_receipt`,
      type: 'POST',
      data: {
        email
      },
      beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
      success: (data) => {
        callback(data)
      },
      error: function(xhr, status, error) {
        let message = error;
        if (xhr.responseJSON && xhr.responseJSON.data && xhr.responseJSON.data.error) {
          message = xhr.responseJSON.data.error;
        }
        alert(message)
      }
    })
  },

  createReceipt(id, callback) {
    let token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      url: `/payments/${id}/create_receipt`,
      type: 'POST',
      data: {},
      beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
      success: (data) => {
        callback(data)
      }
    })
  }
}