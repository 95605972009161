export default {
  fields: [
    { name: ".status", opts: [
        "active", "past_due", "unpaid", "canceled", "paused", "deleted", "one_time", // Memberships
        "success", "failed", "paid", "cancelled", // Purchases
        "inactive", "archived", // Discount Rule
      ]
    }
  ],
  init(){
    this.search_for_matches()
  },
  search_for_matches(){
    for (let y = 0; y < this.fields.length; y++) {
      const field = this.fields[y];
      var matches = document.querySelectorAll('table.index-table ' + field.name)
      for (let x = 0; x < matches.length; x++) {
        const element = matches[x];
        for (let z = 0; z < field.opts.length; z++) {
          const option = field.opts[z];
          if(element.innerText && element.innerText.toLowerCase() == option){
            element.className += " " + option
          }
        }
      }
    }
  }
}
