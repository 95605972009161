export default {
  $branches: $('input.branches'),
  $default_branch: $('#app_default_branch_id'),

  initForm: function () {
    this.$branches.on('change', () => this.handleBranchChange())
    this.$default_branch.on('change', () => this.handleBranchChange())
  },

  handleBranchChange: function () {
    let branch_id = this.$default_branch.val()
    $.each(this.$branches, function (index, branch) {
      if ($(branch).val() == branch_id) {
        $(branch).prop('checked', true)
      }
    })
  }
}