import React, {Component, useEffect, useState} from "react";

const Control = ({error, title, description, children}) => {


  return (
    <div className="form-group">
			<div className="input-group">
				<div className="input-group-prepend">
					<span className="input-group-text equalWidth">{title}</span>
				</div>
				{children}
			</div>
			{description && <span className="d-flex d-inline-flex"><span className="flex-shrink-0 equalWidth"></span><small className="form-text text-muted text-small">{description}</small></span>}
			{error && error.length>0 && <div className="small text-right help-block text-danger">{error}</div>}
			
		</div>
  );

}

export default Control;
