
require.register("formik/dist/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "formik");
  (function() {
    'use strict'

if ('development' === 'production') {
  module.exports = require('./formik.cjs.production.min.js')
} else {
  module.exports = require('./formik.cjs.development.js')
}
  })();
});