import {axiosInstance} from "./axios";

export const getAccessToken = async () => {
  const request = {
    url: "/api/access_token",
    method: 'GET',
    headers: {},
  };
  const response = await axiosInstance.request(request);
  return response.data;
}

let accessToken = null;

export async function fetchAccessToken() {
  if (accessToken) {
    return accessToken;
  }
  accessToken = await getAccessToken();
  return accessToken;
}

export async function apiRequest(params) {
  const {url, headers} = params;
  const {api_base_url, token, api_key} = await fetchAccessToken();
  const request = {
    baseURL: api_base_url,
    headers: {
      "Content-type": "application/json",
      "Authorization": `Bearer ${token}`,
      "x-api-key": api_key,
      ...headers,
    },
    ...params,
  };
  const response = await axiosInstance.request(request);
  throwIfError(response)
  return response;
}

function throwIfError(response) {
  if (response.data.errors && response.data.errors.length > 0) {
    throw new Error(response.data.errors.map(i => i.message).join(" "));
  }
}

