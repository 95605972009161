import React, { useState } from "react";
import { Modal } from "../../components/Modal";
import WalletPassDataEditor from "../../components/walletPass/WalletPassDataEditor";



const WalletPassDataEditorApp = (props) => {

  const [visible, setVisible] = useState(false);

  const onDataSubmitHandler = (values) => {
    document.querySelector(`[data-editor="walletPassDataEditor"]`).value = JSON.stringify(values, undefined, 2);
    setVisible(false);
  }

  const onDataResetHandler = (values) => {
    // setVisible(false);
  }

  return <div>
    <button type="button" className="btn btn-primary" onClick={() => setVisible(true)}>Edit Configuration</button>
    <Modal title="Wallet Pass Configuration" show={visible} onClose={() => setVisible(false)}>
        <WalletPassDataEditor {...props}  onDataSubmit={onDataSubmitHandler} onDataReset={onDataResetHandler}/>
    </Modal>
  </div>

};

export default WalletPassDataEditorApp;