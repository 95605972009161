import React from "react";
import * as Yup from "yup";
import DataField from "./controls/DataField";
import DataForm from "./controls/DataForm";

const MembershipTypeDataEditor = ({
  value,
  onDataReset,
  onDataSubmit,
  storagePath,
}) => {
  const defaultValues = {};

  const expectedFiles = [
    { filename: "strip.png", description: "Apple, 375x123 px" },
    { filename: "strip@2x.png", description: "Apple, 750x246 px" },
    { filename: "strip@3x.png", description: "Apple, 1125x369 px" },
    { filename: "logo.png", description: "Apple, 160x50 px" },
    { filename: "logo@2x.png", description: "Apple, 320x100 px" },
    { filename: "logo@3x.png", description: "Apple, 480x150 px" },
    { filename: "google-logo.png", description: "Google, 660x660 px" },
    { filename: "google-hero.png", description: "Google, 1032x336 px" },
  ];

  const validationSchema = Yup.object({
    labelColor: Yup.string().optional(),
    foregroundColor: Yup.string().optional(),
    backgroundColor: Yup.string().optional(),
    stripColor: Yup.string().optional(),
  });

  return (
    <DataForm
      value={value}
      onDataReset={onDataReset}
      onDataSubmit={onDataSubmit}
      storagePath={storagePath}
      defaultValues={defaultValues}
      expectedFiles={expectedFiles}
      validationSchema={validationSchema}
    >
      <div className="card">
        <div className="card-header">Apple Wallet</div>
        <div className="card-body">
          <DataField
            field="labelColor"
            type="text"
            label="Label Color"
            placeholder="#FFFFFF or rgb(255, 255, 255)"
          />
          <DataField
            field="foregroundColor"
            type="text"
            label="Foreground Color"
            placeholder="#FFFFFF or rgb(255, 255, 255)"
          />
          <DataField
            field="stripColor"
            type="text"
            label="Strip Text Color"
            placeholder="#FFFFFF or rgb(255, 255, 255)"
          />
          <DataField
            field="backgroundColor"
            type="text"
            label="Background Color"
            placeholder="#000000 or rgb(0, 0, 0)"
          />
        </div>
      </div>
      <div className="card">
        <div className="card-header">Google Wallet</div>
        <div className="card-body">
          <DataField
            field="googleBackgroundColor"
            type="text"
            label="Background Color"
            placeholder="#000000 or rgb(0, 0, 0)"
          />
        </div>
      </div>
    </DataForm>
  );
};

export default MembershipTypeDataEditor;
