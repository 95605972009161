export default {
  init (resource) {
    this.container = document.getElementById(`${resource}-container`)

    let $orderLink = $(`#${resource}-tab`)    

    if ($orderLink.hasClass('active')) {
      this.loadOrders(this.container.dataset.root)
    }
    $orderLink.on('show.bs.tab', () => {
      this.loadOrders(this.container.dataset.root)
    })

    $(this.container)
      .on('click', '.page-link', this.onPagination.bind(this))
      .on('click', '.per-page-nav a', this.onPagination.bind(this))
      .on('click', '.clickable-row', function() {
        window.location.href = $(this).attr('data-href')
      })
  },

  loadOrders(url) {
    $.ajax({
      url: url,
      method: 'GET'
    })
    .done((content) => {
      this.container.innerHTML = content
      this.replacePaginationLinks()
    })
  },

  replacePaginationLinks () {
    let newPath = this.container.dataset.root
    let links = $(this.container).find('.page-link, .per-page-nav a')
    $.each(links, function(index, link) {
      let href = link.href.replace('/purchases', newPath).replace('/company_ticket_orders', newPath).replace('/memberships', newPath)
      link.href = href
    })
  },

  onPagination (e) {
    e.preventDefault()
    this.loadOrders(e.target.href)
  }
}