
require.register("date-fns/_lib/defaultLocale/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "date-fns");
  (function() {
    "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("../../locale/en-US/index.js"));
var _default = _index.default;
exports.default = _default;
module.exports = exports.default;
  })();
});