import equalWidth from "../equalWidth";

export default {
  config: {
    moreFilters: false,
    labels: {
      lessFilters: "Less filters -",
      moreFilters: "More filters +"
    }
  },
  init() {
    console.log("Hello")
    this.moreOrLessFilters()
  },
  moreOrLessFilters() {
    var button          = document.getElementById("more-filters");
    // Dont continue if we dont have the more-filters button.
    if(!button)
      return false

    var hiddenElements  = document.querySelectorAll('.hidden-filter')
    var self = this

    button.addEventListener('click', function(e){
      e.preventDefault()
      if (!self.config.moreFilters){
        for (var i = hiddenElements.length - 1; i >= 0; i--) {
          hiddenElements[i].classList.remove('hide')
        }
        equalWidth.resizeElements(true)
        button.innerHTML = self.config.labels.lessFilters

        self.config.moreFilters = true
      } else {
        for (var i = hiddenElements.length - 1; i >= 0; i--) {
          self.clearInput(hiddenElements[i])
          hiddenElements[i].classList.add('hide')
        }
        button.innerHTML = self.config.labels.moreFilters
        console.log(button.closest('form.form'))
        self.config.moreFilters = false
      }
    });
  },
  clearInput(element){
    for (var i = element.childNodes.length - 1; i >= 0; i--) {
      var input = element.childNodes[i].querySelector('.form-control')
      if(input) {
        input.value = ""
      }
    }
  }
}
