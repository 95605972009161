
require.register("object-is/polyfill.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "object-is");
  (function() {
    'use strict';

var implementation = require('./implementation');

module.exports = function getPolyfill() {
	return typeof Object.is === 'function' ? Object.is : implementation;
};
  })();
});