
require.register("react-hook-form/dist/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-hook-form");
  (function() {
    'use strict'

if ('development' === 'production') {
  module.exports = require('./index.cjs.production.min.js')
} else {
  module.exports = require('./index.cjs.development.js')
}
  })();
});