import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";

const DataField = ({ field, label, description, type, ...rest }) => {
  return (
    <div className="form-group">
      <div className="input-group">
        <div className="input-group-prepend">
          <label className="ml-0 input-group-text" htmlFor={field}>
            {label}
          </label>
        </div>
        {type == "checkbox" ? (
          <div className="form-control checkboxes">
            <div className="custom-control custom-checkbox">
              <Field
                name={field}
                id={field}
                type={type}
                {...rest}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor={field}>
                &nbsp;
              </label>
            </div>
          </div>
        ) : (
          <Field name={field} type={type} {...rest} className="form-control" />
        )}
      </div>
      <ErrorMessage
        name={field}
        className="text-danger small"
        component="div"
      />
      {description && <p className="mt-1 ml-2"><small className="text-muted">{description}</small></p>}
    </div>
  );
};


export default DataField;
