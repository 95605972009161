import React, { useEffect, useState, useRef } from "react";

function getStyleProps(size) {
  let styleProps;
  switch (size) {
    case "full":
      styleProps = { maxWidth: "95%" };
      break;
    case "wide":
      styleProps = { maxWidth: "1280px" };
      break;
    default:
      styleProps = { maxWidth: "700px" };
      break;
  }
  return styleProps;
}

export const Modal = ({
  show,
  title = "Blank",
  children = [],
  size = "normal",
  onClose,
}) => {
  const modal = React.createRef();
  useEffect(() => {
    if (modal.current) {
      $(modal.current).on("hide.bs.modal", function () {
        onClose && onClose();
      });
    }
  }, [modal.current]);

  useEffect(() => {
    if (show) {
      $(modal.current).modal("show");
    } else {
      $(modal.current).modal("hide");
    }
  }, [show]);

  const onCloseHandler = () => {
    onClose && onClose();
  };

  return (
    <div
      className="modal modal-dark fade"
      ref={modal}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document" style={getStyleProps(size)}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onCloseHandler}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-left">{children}</div>
        </div>
      </div>
    </div>
  );
};
