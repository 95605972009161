
require.register("date-fns/_lib/defaultOptions/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "date-fns");
  (function() {
    "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDefaultOptions = getDefaultOptions;
exports.setDefaultOptions = setDefaultOptions;
var defaultOptions = {};
function getDefaultOptions() {
  return defaultOptions;
}
function setDefaultOptions(newOptions) {
  defaultOptions = newOptions;
}
  })();
});