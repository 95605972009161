
require.register("has-tostringtag/shams.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "has-tostringtag");
  (function() {
    'use strict';

var hasSymbols = require('has-symbols/shams');

module.exports = function hasToStringTagShams() {
	return hasSymbols() && !!Symbol.toStringTag;
};
  })();
});