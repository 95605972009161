export default {
  init() {
    $('.sidebar').on('click', '.menu-burger', (e) => {
      e.preventDefault()
      $('.sidebar').toggleClass('expanded')
    })
  },

}

