function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

function loadCourseEvents() {
  var course_id = $('#course_id').val();
  $('#course_event_id').html('<option value="">Any</option>');
  if (course_id) {
    let url = '/courses/' + course_id + '/events';
    $.ajax({
      url: url,
      type: 'GET',
      success: function (data) {
        data = $.parseJSON(data);
        $('#course_event_id').prop('disabled', false);
        if (data.length) {
          $.each(data, function (idx, event) {
            var option = $('<option></option>');
            option.attr('value', event.id);
            option.text(event.start_date + " - " + event.start_time);
            $('#course_event_id').append(option);
          });
          var q = getUrlParameter('q%5Bcourse_event_id_eq%5D');
          if (q) {
            $('#course_event_id').val(q);
          }
        }
      }
    });
  } else {
    $('#course_event_id').val('').prop('disabled', true);
  }
}

export default {
  init() {
    var course_id = getUrlParameter('course_id');

    if (course_id) {
      $('#course_id').val(course_id);
      loadCourseEvents();

    }
    $('body').on('change', '#course_id', function (e) {
      loadCourseEvents();
    });
  }
}