const TicketWizard = {
  init() {
    this.$wizard = $('#ticket-wizard')
    this.$validFrom = $('#valid-from')
    this.$validUntil = $('#valid-until')
    this.formData = {}
    this.ticket = {}
    this.token = $('meta[name="csrf-token"]').attr('content')
    this.setupFields()
    this.setupListeners()
  },

  setupFields() {
    var DateTimeOptions = {
      format: "DD.MM.YYYY",
      startDate: new Date(),
      trigger: $('#valid-from-group .fa-calendar')
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.$validFrom.datepicker(DateTimeOptions);
    DateTimeOptions.trigger = $('#valid-until-group .fa-calendar')
    this.$validUntil.datepicker(DateTimeOptions);
    this.$validFrom.on("pick.datepicker", (e) => {
      this.$validUntil.datepicker('setStartDate', e.date)
    });
    this.$validUntil.on("pick.datepicker", (e) => {
      this.$validFrom.datepicker('setEndDate', e.date)
      //that.$validFrom.data("DateTimePicker").maxDate(e.date);
    })
  },

  setupListeners() {
    let that = this
    $('a[data-toggle="tab"]').on('show.bs.tab', (e) => {
      var $target = $(e.target)
      if ($target.hasClass('disabled')) {
        return false;
      } else {
        var identifier = $target.attr('href')
        this.prepareTab(identifier)
      }
    })
    this.$wizard.on('click', 'li.next a', (e) => {
      var $active = that.$wizard.find('.nav-tabs a.active')
      var identifier = $active.attr('href')
      if (this.validateTab(identifier)) {
        this.setFormValues(identifier)
        $active.parent().next().find('a').removeClass('disabled')
        that.next($active)
      }
    })
      .on('click', 'li.order a', (e) => {
        // $('li.order a').off('click')
        // $('li.order a').html("wait while we create the tickets for you")
        var $message = $('<span>Please wait while we generate the tickets for you...</span>')
        var message = $message.clone().insertAfter($('li.order a').fadeOut(0))
        $('li.previous a').fadeOut(0)
        this.placeOrder(message)
      })
      .on('click', 'li.previous a', (e) => {
        var $active = that.$wizard.find('.nav-tabs a.active')
        that.previous($active)
      })
      .on('click', 'li.finish a', (e) => {
        this.reset()
      })
      .on('click', '#new-company', (e) => {
        e.preventDefault()
        var $target = $(e.target)
        if ($target.text() == "New") {
          $('#new-company-form').removeClass('d-none')
          $target.text('Cancel')
          $target.removeClass('btn-primary').addClass('btn-default')
        } else {
          $('#new-company-form').addClass('d-none')
          $target.text('New')
          $target.addClass('btn-primary').removeClass('btn-default')
        }
      })
      .on('click', '#new-company-save', (e) => {
        e.preventDefault()
        let name = $('#new-company-name').val()
        if (name == "") {
          alert('Please enter a name')
        } else {
          let token = this.token
          let branch_id = this.$wizard.attr('data-branch')
          $.ajax({
            url: '/tickets/create_company',
            type: 'POST',
            data: { company: { name: name, branch_id: branch_id } },
            beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
            success: (data) => {
              data = $.parseJSON(data)
              let opt = $('<option value="' + data.id + '">' + data.name + '</option>')
              $('select#company-select').prepend(opt).val(data.id)

              $('#new-company-name').val('')
              $('#new-company').trigger('click')
            }
          })
        }
      })
  },

  next(elem) {
    $(elem).parents('li').next().find('a[data-toggle="tab"]').click()
  },

  previous(elem) {
    $(elem).parents('li').prev().find('a[data-toggle="tab"]').click()
  },

  validateTab(identifier) {
    if (identifier == '#ticket-type') {
      if ($('#select-ticket-type').val() == '') {
        alert('Please select a ticket type')
        return false
      }
      if (parseInt($('#ticket-amount').val()) < 1 || parseInt($('#ticket-amount').val()) > 1000) {
        alert('Please correct the amount of tickets, min 1, max 1 000')
        return false
      }
    }
    return true
  },

  setFormValues(identifier) {
    if (identifier == '#ticket-type') {
      this.formData['ticket-type'] = $('#select-ticket-type').val()
      this.formData['ticket-type-name'] = $('#select-ticket-type option[value="' + this.formData["ticket-type"] + '"]').text()
      this.formData['ticket-amount'] = $('#ticket-amount').val()
    }

    if (identifier == '#ticket-time') {
      this.formData['valid-from'] = this.$validFrom.val()
      this.formData['valid-until'] = this.$validUntil.val()
    }

    if (identifier == '#company') {
      this.formData['company'] = $('#company-select').val()
      this.formData['company-name'] = $('#company-select option[value="' + this.formData["company"] + '"]').text()
    }
  },

  prepareTab(identifier) {
    if (identifier == "#confirm") {
      var $confirm = $('#confirm')
      for (var key in this.formData) {
        $confirm.find('.ticket-value[data-value="' + key + '"]').html(this.formData[key]);
      }
    }
    if (identifier == '#download') {
      var $download = $('#download')
      for (var key in this.formData) {
        $download.find('.ticket-value[data-value="' + key + '"]').html(this.formData[key]);
      }
      $download.find('.ticket-value[data-value="code"]').html(this.ticket['code'])
      var $tabs = this.$wizard.find('.nav-tabs li')
      $tabs.addClass('disabled')
    }
  },

  placeOrder(element_to_hide) {
    if (this.formData['ticket-type'] == '') {
      alert('Please select a ticket type')
      return
    }
    var data = {
      ticket_type_id: this.formData['ticket-type'],
      company_id: this.formData['company'],
      valid_from: this.formatDate(this.formData['valid-from']),
      valid_until: this.formatDate(this.formData['valid-until']),
      amount: this.formData['ticket-amount']
    }
    var download_ticket = function(codes, $download_link) {
      var $message = $('<span>Please wait while we generate the PDF for you. This could take minutes depending on how many tickets you have requested.</span>')
      var cloned_message = $message.clone().insertAfter($download_link.fadeOut(0))
      var code_type = $download_link.data('code-type');
      $download_link.siblings('a').fadeOut(0)
      $.ajax({
        url: '/tickets/generate_pdf',
        method: 'POST',
        beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
        data: { ids: codes.join(","), code_type: code_type },
        success: (file) => {
          file = $.parseJSON(file)
          window.open(document.location.origin + "/tickets/download_file?path=" + file.url)
          $(cloned_message).remove();
          $download_link.fadeIn('fast')
          $download_link.siblings('a').fadeIn('fast')
        }
      })
    }
    let token = this.token
    $.ajax({
      url: '/tickets/create_custom',
      method: 'POST',
      data: { ticket: data },
      beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
      success: (response) => {
        $(element_to_hide).remove()
        $('li.previous a, li.order a').fadeIn(0)
        response = $.parseJSON(response)
        this.ticket = data
        this.ticket['code'] = response.code
        var $download_link = $('a.download-ticket-button')
        $download_link.on('click', e => download_ticket(response.codes, $(e.target)) )
        $('#confirm').find('li.next a').trigger('click')
      },
      failure: (response) => {
        response = $.parseJSON(response)
        alert('Unable to generate ticket')
        console.log(response)
      }
    })
  },

  reset() {
    window.location.reload()
  },

  formatDate(datetime) {
    var timestamp = Date.parse(datetime)
    if (isNaN(timestamp)) {
      return null
    }
    var date = new Date(timestamp)
    return date.toISOString()
  }
}

$(document).ready(function () {
  TicketWizard.init()
})