import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import WalletPassApi from "../../../api/WalletPassApi";
import { axiosInstance } from "../../../lib/axios";

const File = ({ description, filename, file, preview, url, loading }) => {
  return (
    <div className="card border border-secondary">
      <div
        style={{
          backgroundImage:
            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100'%3E%3Cdefs%3E%3Cpattern id='a' width='20' height='20' patternUnits='userSpaceOnUse'%3E%3Cpath fill='rgba(0,0,0,0.5)' d='M0 0h10v10H0zM10 10h10v10H10z'/%3E%3C/pattern%3E%3C/defs%3E%3Cpath fill='url(%23a)' d='M0 0h100v100H0z'/%3E%3C/svg%3E\")",
        }}
      >
        {preview && (
          <img
            src={preview}
            className="img-fluid"
            onLoad={() => {
              URL.revokeObjectURL(preview);
            }}
          />
        )}
        {!preview && url && (
          <a href={url} target="_blank">
            <img src={url} className="img-fluid" />
          </a>
        )}
        {!preview && !url && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="680.764"
            height="528.354"
            viewBox="0 0 180.119 139.794"
            className="img-fluid"
          >
            <g
              transform="translate(-13.59 -66.639)"
              paintOrder="fill markers stroke"
            >
              <path fill="#d0d0d0" d="M13.591 66.639H193.71v139.794H13.591z" />
              <path
                d="m118.507 133.514-34.249 34.249-15.968-15.968-41.938 41.937H178.726z"
                opacity=".675"
                fill="#fff"
              />
              <circle
                cx="58.217"
                cy="108.555"
                r="11.773"
                opacity=".675"
                fill="#fff"
              />
              <path fill="none" d="M26.111 77.634h152.614v116.099H26.111z" />
            </g>
          </svg>
        )}
      </div>
      <div className="card-footer bg-dark text-light">
        {filename}
        <div className="text-gray">
          <small>{description}</small>
        </div>
      </div>
    </div>
  );
};

const FilesInput = ({
  files,
  storagePath,
  expectedFiles,
  onFilesUploaded,
  uploadTrigger,
}) => {
  const [newFiles, setNewFiles] = useState([]); // [ { filename, file, preview } ]

  const [existingFiles, setExistingFiles] = useState(files || []);

  const [matchedFiles, setMatchedFiles] = useState([]);

  useEffect(() => {
    const findNewFile = (filename) => {
      return newFiles.find((newFile) => newFile.filename === filename);
    };

    const findExistingFile = (filename) => {
      return existingFiles.find(
        ({ key: key }) => key.split("/").reverse()[0] === filename
      );
    };

    setMatchedFiles(
      expectedFiles.map(({ filename, description }) => {
        return {
          ...(findNewFile(filename) ||
            findExistingFile(filename) || { filename: filename }),
          description,
        };
      })
    );
  }, [existingFiles, expectedFiles, newFiles]);

  useEffect(() => {
    Promise.all(
      (files || []).map(async (file) => {
        return await WalletPassApi.getUpload(file);
      })
    ).then((files) => {
      setExistingFiles(files);
    });
  }, [files]);

  useEffect(() => {
    const mergeFiles = (oldFiles, newFiles) => {
      return [...(oldFiles || []), ...newFiles]
        .map(({ path, filename, key }) => {
          return {
            path,
            filename,
            key,
          };
        })
        .reduce((acc, file) => {
          if (
            acc.find(({ filename: f }) => f === file.filename) !== undefined
          ) {
            return acc;
          } else {
            return [...acc, file];
          }
        }, []);
    };
    if (uploadTrigger > 0) {
      uploadAllFiles().then((uploadedFiles) => {
        const mergedFiles = mergeFiles(files, uploadedFiles);
        onFilesUploaded && onFilesUploaded(mergedFiles);
        setNewFiles([]);
      });
    }
  }, [uploadTrigger]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      newFiles.forEach((file) => {
        URL.revokeObjectURL(file.preview);
      });
  }, []);

  const uploadFile = async (upload, file) => {
    return new Promise((resolve, reject) => {
      try {
        axiosInstance
          .put(upload.url, file, {
            onUploadProgress: (progressEvent) => {
              const uploadPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              // console.log(
              //   "uploadPercentage",
              //   file.name,
              //   upload.url,
              //   uploadPercentage
              // );
            },
            headers: {
              "Content-Type": file.type,
            },
          })
          .then((res) => {
            resolve(upload);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (e) {
        reject(e);
      }
    });
  };

  const matchFiles = async (files) => {
    const newFiles = [];
    for (const file of files) {
      const filename = file.name;
      if (
        expectedFiles.find(({ filename: f }) => f === filename) !== undefined
      ) {
        const uploadObject = await WalletPassApi.createUpload({
          path: storagePath,
          filename,
        });
        setNewFiles((newFiles) => [
          ...newFiles.filter(({ filename: f }) => f !== filename),
          {
            filename,
            file,
            upload: uploadObject,
            preview: URL.createObjectURL(file),
          },
        ]);
      }
    }
  };

  const uploadAllFiles = async () => {
    return await Promise.all(
      newFiles.map(async ({ upload, file }) => await uploadFile(upload, file))
    );
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    matchFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div className="card border border-secondary" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="card-body text-secondary" style={{ cursor: "pointer" }}>
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>
              Drop images here or click to select,
              <br />
              ensuring filenames and sizes match those below.
            </p>
          )}
        </div>
      </div>

      <div className="row">
        {matchedFiles.map((file) => (
          <div className="col-4" key={file.filename}>
            <File {...file} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilesInput;
