
export default {
  init() {
    $('body').on('change', '#search_event_group_action_eq', function (e) {
      const [group, action] = (e.target.value || ".").split('.');
      document.querySelector('#search_event_group_eq').value = group;
      document.querySelector('#search_event_action_eq').value = action;
    });
    document.querySelector('#search_event_group_action_eq').dispatchEvent(new Event('change'));
  }
}