import React from "react";
import {formatTimeslotDate, formatTimeslotTimeRange} from "../../lib/purchase/timeslot";

function groupTickets(tickets) {
  return Object.values(tickets.reduce((acc, ticket) => {
    const displayedTimeslot = getDisplayedTimeslot(ticket.timeslots)
    const key = JSON.stringify({
      status: ticket.status,
      timeslot: displayedTimeslot ? {
        timeslotDate: displayedTimeslot.timeslotDate,
        timeslotStart: displayedTimeslot.timeslotStart,
        timeslotEnd: displayedTimeslot.timeslotEnd,
      } : null,
    });
    const curVal = key in acc ? acc[key] : {
      timeslot: displayedTimeslot,
      status: ticket.status,
      numUsages: 0,
    };
    const newVal = {
      ...curVal,
      numUsages: (curVal.numUsages + ticket.numUsages),
    }
    return {
      ...acc,
      [key]: newVal,
    }
  }, {})).sort((
    {status: statusA},
    {status: statusB}) => {
    return statusA == statusB ? 0 : (
      statusA == 'unused' ? -1 : 1
    );
  });
}

function getDisplayedTimeslot(timeslots) {
  if (timeslots.length == 1 && timeslots[0].timeslotDate!=null) {
    return timeslots[0];
  } else {
    return null;
  }
}

export function LineItemTickets({tickets, timeFormat}) {

  const ticketGroups = groupTickets(tickets);

  return <div>
    {ticketGroups.map((ticketGroup, index) => {
        return <div key={index} className={"small " + (ticketGroup.status == 'unused' ? '' : 'text-muted')}>
          <React.Fragment>
            {ticketGroup.timeslot &&
              <React.Fragment>{formatTimeslotDate(ticketGroup.timeslot.timeslotDate) + " " + formatTimeslotTimeRange(ticketGroup.timeslot, timeFormat)}: </React.Fragment>
            }
            {ticketGroup.numUsages} {ticketGroup.status}
          </React.Fragment>
        </div>;
      }
    )}
  </div>;
}