import {LineItemRow} from "./LineItemRow";
import React, {useCallback} from "react";


export function LineItems({total, netTotal, lineItems, selectedIds = [], onSelect, onChange, editMode, timeFormat, version}) {

  const isAllSelected = () => {
    return lineItems.length == selectedIds.length;
  }

  const onSelectionChange = (id, selected) => {
    if (selected) {
      onSelect([...new Set([
        ...selectedIds,
        id
      ])]);
    } else {
      onSelect(selectedIds.filter(i => i != id));
    }
  };

  const onAllSelectionChange = (e) => {
    if (e.target.checked) {
      onSelect(lineItems.map(li => li.id));
    } else {
      onSelect([]);
    }
  }

  return <table className="w-100 table table-striped table-hover">
    <thead>
    <tr>
      {editMode && <th>
        <label>
          <input type="checkbox" checked={isAllSelected()} onChange={onAllSelectionChange}/> All
        </label>
      </th>}
      <th>
        #
      </th>
      <th>
        Product
      </th>
      <th>
        Tickets
      </th>
      <th>
        Arrival
      </th>
      <th>
        Price type
      </th>
      <th>
        Quantity
      </th>
      <th>
        Net price
      </th>
      <th>
        Price
      </th>
      <th>
        Net total
      </th>
      <th>
        Total
      </th>
    </tr>
    </thead>
    <tbody>
    {lineItems.map((lineItem) => {
      const onSelectLineItem = (selected) => onSelectionChange(lineItem.id, selected);
      const onChangeLineItem = (changes) => onChange(lineItem.id, changes);

      return (
        <LineItemRow key={lineItem.id} lineItem={lineItem}
                     onSelect={onSelectLineItem}
                     selected={selectedIds.includes(lineItem.id)}
                     onChange={onChangeLineItem}
                     editMode={editMode}
                     timeFormat={timeFormat}
                     version={version}/>
      );
    })}
    </tbody>
    <thead>
      {netTotal!==null && <tr>
        {editMode && <td></td>}
        <td colSpan={8}></td>
        <td colSpan={1} className="text-right text-muted">Net total:</td>
        <td colSpan={1} className="text-muted">{netTotal}</td>
      </tr>}
      <tr>
        {editMode && <td></td>}
        <td colSpan={8} className=""></td>
        <td colSpan={1} className="font-weight-bold text-right">TOTAL:</td>
        <td colSpan={1} className="font-weight-bold">{total}</td>
      </tr>
    </thead>
  </table>;
}