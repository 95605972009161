import ordersTab from './ordersTab'

$(document).ready(function() {
  let url = document.location.toString()
  if (url.match('#')) {
    $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show')
    window.scrollTo(0,0)
  }
  $('.nav-tabs a').on('shown.bs.tab', function(e) {
    window.location.hash = e.target.hash
    window.scrollTo(0,0)
  })


  document.getElementById('orders-tab') && ordersTab.init('orders')
  document.getElementById('memberships-tab') && ordersTab.init('memberships')

  const editLink = $('<a href="#" class="edit">Edit</a>')
  const cancel = $('<a href="#" class="cancel">Cancel</a>')
  const save = $('<a href="#" class="save">Save</a>')

  function onUpdateFormContent(formParent) {
    // initialize controls if needed after ajax update content
    formParent.find('select.select2').select2()
    formParent[0].dispatchEvent(new Event('tabbedShowFormUpdated'))
  }

  function saveFormRemote(elements) {
    let form = elements.form
    let partial = `_form_${elements.scope.replace('-', '_')}.html`

    form
      .prepend('<input type="hidden" name="submit_type" value="inline" />')
      .prepend('<input type="hidden" name="partial", value="' + partial + '" />')

    $.post(
      form.attr('action'),
      form.serialize()
    ).done((data) => {
      const formParent = form.parent()
      formParent.html(data)
      onUpdateFormContent(formParent)
      $('.allow-edit[data-rel="' + elements.scope + '"]').removeClass('editing')
      elements.actions.html(editLink)
      elements.form.off('submit.tabbedShow')
    }).fail((error) => {
      let json = error.responseJSON
      if (json.errors) {
        let keys = Object.keys(json.errors)
        let errors = []
        for (let i = 0; i < keys.length; i++) {
          let message = json.errors[keys[i]].join(', ')
          errors.push(`${keys[i]}: ${message}`)
        }
        let alert = $('<div class="alert alert-danger"></div>')
        alert.html(errors.join("<br>\n"))
        $('main').find('.container-fluid').prepend(alert)
        setTimeout(() => {
          alert.remove()
        }, 4000)
      }
    })
  }

  function getElements(target) {
    let editInlineActions = $(target).parent()
    let scope = editInlineActions.attr('data-rel')
    let $container = $('.allow-edit[data-rel="' + scope + '"]')
    let form = $container.find('form')
    return {
      actions: editInlineActions,
      scope: scope,
      container: $container,
      form: form
    }
  }

  function checkformEdits(currentElement){
    var details = $('.card-header.editable-header').find('[data-rel=details]')
    var detailsAdmin = $('.card-header.editable-header').find('[data-rel=details-admin]')

    if (details.children().length > 1 && currentElement.scope == 'details-admin') {
      detailsAdmin.html('').append(cancel.clone()).append(" ").append(save.clone())
    } else if(detailsAdmin.children().length > 1 && currentElement.scope == 'details') {
      details.html('').append(cancel.clone()).append(" ").append(save.clone())
    }
    else {
      currentElement.actions.html('').append(cancel).append(" ").append(save)
    }
  }


  $('.edit-inline-actions').on('click', '.edit', (e) => {
    e.preventDefault()
    let elements = getElements(e.target)
    $('.allow-edit[data-rel="' + elements.scope + '"]').addClass('editing')
    elements.form.on('submit.tabbedShow', (e) => {
      e.preventDefault();
      saveFormRemote(elements)
    })
    checkformEdits(elements)
    elements.form[0].dispatchEvent(new Event('afterEdit.tabbedShow'));
  }).on('click', '.cancel', (e) => {
    e.preventDefault()
    let elements = getElements(e.target)
    $('.allow-edit[data-rel="' + elements.scope + '"]').removeClass('editing')
    elements.actions.html(editLink.clone())
    elements.form.off('submit.tabbedShow')
  }).on('click', '.save', (e) => {
    e.preventDefault()
    let elements = getElements(e.target)
    // saveFormRemote(elements)
    elements.form[0].dispatchEvent(new Event('beforeSubmit.tabbedShow'));
    elements.form[0].dispatchEvent(new Event('submit'));
  })

  $('.tab-toggle').on('click', (e) => {
    e.preventDefault();
    $('.nav-tabs a[href="' + $(e.target).attr('href') + '"]').click()
  })
})