import {apiRequest} from "../lib/api";

export default class ProductApi {

  static async get(id) {
    const response = await apiRequest({
      url: "/graph",
      method: "POST",
      data: {
        "operationName": "getProduct",
        "query": `      
        query getProduct($productId: Int!) {
          product(id: $productId){
            id
            name
            ticketType {
              id
              timeslotGroup {
                id
                name
                timeslots {
                  id
                  timeslotDate
                  timeslotStart
                  timeslotEnd
                  datetime
                }
              }
            }
          }
        }
      `,
        "variables": {
          'productId': id,
        }
      },
    })
    return response.data.data && response.data.data.product;
  }

}