import chartJs from 'chart.js/dist/Chart.bundle.min.js';

$(document).ready(function () {
  if ($('#membershipData').length > 0 || $('#tickets-over-time').length > 0) {
    Statistics.init()
  }
  document.getElementById("users-over-time") && ChartUsersOverTime();
  document.getElementById("members-per-month-and-type") && ChartMembershipsPerMonthAndType();
})

const chartColors = ["#69d2e7", "#a7dbd8", "#e0e4cc", "#f38630", "#fa6900", "#fe4365", "#fc9d9a", "#f9cdad", "#c8c8a9", "#83af9b", "#ecd078", "#d95b43", "#c02942", "#542437", "#53777a"]
window.chartColors = chartColors

const Statistics = {
  init: function () {
    this.Memberships.init();
    this.Tickets.init();
  },
  Memberships: {
    init: function () {
      this.populateTypeData()
    },
    populateTypeData: function () {
      var item = document.getElementById("membershipData")
      var json_data = (item) ? JSON.parse($(item).attr('data')) : false
      var tbody = document.getElementById("insertMembershipData")
      if (item && json_data && json_data) {
        var $tr = $('<tr></tr>');
        var $td = $('<td></td>');
        var totals = [];

        for (var i = json_data.length - 1; i >= 0; i--) {

          var $tr_clone = $tr.clone()

          for (var y = json_data[i].data.length - 1; y >= 0; y--) {
            totals[y] = totals[y] || 0
            totals[y] = totals[y] + json_data[i].data[y].count

            $tr_clone.prepend($td.clone().text(json_data[i].data[y].count).attr('period', json_data[i].data[y].period));
          }

          var $name = $td.clone().text(json_data[i].name)

          $tr_clone.prepend($name).appendTo(tbody)
        }

        // add the totals
        $tr_clone = $('<tr><th scope="row">Total</th></tr>');
        for (var y = 0; y < totals.length; y++) {
          $tr_clone.append($('<td>' + totals[y] + '</td>'))
        }
        $tr_clone.appendTo(tbody)


        // $(item).DataTable();

      }
    },
    populateSumOfTypes: function () {
      var item = document.getElementById("insert-per-membership-type-here")
      var json_data = (item) ? JSON.parse($(item).attr('data')) : false
      if (item && json_data) {

        var $h3 = $('<h4></h4>');
        var $p = $('<p></p>');
        var $hr = $('<hr />');
        var $container = $('<div></div>')

        console.log(json_data);
        console.log("data exists");

        for (var i = json_data.length - 1; i >= 0; i--) {
          var $container_clone = $container.clone().insertAfter(item)
          $h3.clone().text(json_data[i].count).appendTo($container_clone);
          $p.clone().text(json_data[i].name).appendTo($container_clone);
          if (i < json_data.length - 1) {
            $hr.clone().appendTo($container_clone);
          }
        }
      }
    }
  },
  Tickets: {
    init: function () {
      document.getElementById("tickets-over-time") && this.activateChart();
    },
    activateChart: function () {
      var chart = document.getElementById("tickets-over-time");
      var json_data = JSON.parse($(chart).attr('data'));

      console.log(json_data);

      var labels = [];
      var data = [];
      for (var i = json_data.length - 1; i >= 0; i--) {
        labels.push(json_data[i].month)
        data.push(json_data[i].count)
      };

      var ctx = chart.getContext('2d');
      var myChart = new chartJs(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            label: 'Number of users per month',
            data: data,
            backgroundColor: [
              'rgba(255, 255, 255, 0.5)'
            ],
            borderColor: [
              'rgba(255, 255, 255, 1)'
            ],
            borderWidth: 1,
            fill: 'start'
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });

    }
  }
}

window.ChartMembershipsPerMonthAndType = function () {
  var chart = document.getElementById("members-per-month-and-type");
  var json_data = JSON.parse($(chart).attr('data'));

  var labels = [];
  var data = [];
  for (var i = json_data.length - 1; i >= 0; i--) {

    var the_data = []
    for (var x = json_data[i].data.length - 1; x >= 0; x--) {
      the_data.push(json_data[i].data[x].count)
      // only fetch first childs month data
      if (i == 0) {
        labels.push(json_data[i].data[x].month)
      }
    }

    data.push({
      label: json_data[i].name,
      data: the_data,
      backgroundColor: window.chartColors[i]
    })

  };


  var options = {
    // maintainAspectRatio: false,
    spanGaps: false,
    elements: {
      line: {
        tension: 0.4
      }
    },
    scales: {
      yAxes: [{
        stacked: false
      }]
    },
    plugins: {
      filler: {
        propagate: false
      },
      samples_filler_analyser: {
        target: 'chart-analyser'
      }
    }
  };

  var ctx = chart.getContext('2d');
  var myChart = new chartJs(ctx, {
    type: 'bar',
    data: {
      labels: labels,
      datasets: data
    },
    options: options
  });
}

window.ChartUsersOverTime = function () {

  var chart = document.getElementById("users-over-time");
  var json_data = JSON.parse($(chart).attr('data'));

  var labels = [];
  var data = [];
  for (var i = json_data.length - 1; i >= 0; i--) {
    labels.push(json_data[i].month)
    data.push(json_data[i].count)
  };

  var ctx = chart.getContext('2d');
  var myChart = new chartJs(ctx, {
    type: 'line',
    data: {
      labels: labels,
      datasets: [{
        label: 'Number of users per month',
        data: data,
        backgroundColor: [
          'rgba(255, 255, 255, 0.5)'
        ],
        borderColor: [
          'rgba(255, 255, 255, 1)'
        ],
        borderWidth: 1,
        fill: 'start'
      }]
    },
    options: {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }
  });
}