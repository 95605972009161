import React, {useEffect, useState} from "react";
import {LineItems} from "./LineItems";
import {Coupon} from "./Coupon";

const Purchase = ({
                    purchase,
                    editMode,
                    loading,
                    onChangeLineItem,
                    hasChanges,
                    valid,
                    onSave,
                    onCancel,
                    onEditMode,
                    sendTickets,
                    onSendTicketsChange,
                    message,
                    version
                  }) => {

  const {
    id,
    lineItems,
    coupon
  } = purchase;

  const [selectedLineItemIds, setSelectedLineItemIds] = useState([]);


  return <div>
    <LineItems lineItems={lineItems}
               total={purchase.price}
               netTotal={purchase.netPrice}
               selectedIds={selectedLineItemIds}
               onSelect={setSelectedLineItemIds}
               onChange={onChangeLineItem}
               editMode={editMode}
               timeFormat={purchase.branch.config.timeFormat}
               version={version}
    />
    {coupon && <Coupon {...coupon}/>}
    <div className="d-flex flex-row w-100">
      {message &&
        <div className="text-left flex-grow-1 mt-4">
          {message}
        </div>
      }
      <div className="text-right flex-grow-1 mt-4">
        {!editMode &&
          <button className="btn btn-small btn-secondary" onClick={() => onEditMode(true)}>Edit</button>
        }
        {editMode &&
          <React.Fragment>
            <div className="text-right">
              <label><input type="checkbox" checked={sendTickets}
                            onClick={(e) => onSendTicketsChange(e.target.checked)}/> Send updated tickets</label>
            </div>

            <button className="btn btn-primary" onClick={onSave} disabled={loading || !hasChanges || !valid}>Save
            </button>
            <button className="btn btn-link" onClick={onCancel} disabled={loading}>Cancel</button>
          </React.Fragment>
        }
      </div>
    </div>



  </div>;
};

export default Purchase;

