export default {
  init() {
    $(document).on('click', '.user-comment .delete-comment', e => {
      e.preventDefault()

      const url = $(e.target).attr('href')
      const result = confirm("Are you sure you want to delete the comment?");

      if (!result) return false

      this.deleteComment(url, (comment) => {
        $(e.target).closest('.user-comment').remove()
      })
    })

    $(document).on('click', '.user-comment .edit-comment', e => {
      e.preventDefault()

      if ($(e.target).text() == 'Edit') {
        $(e.target).text('Save')
        $(e.target).closest('.user-comment').find('.comment-body').attr('contenteditable','true').focus()
      } else {
        const url = $(e.target).attr('href')
        const payload = { body: $(e.target).closest('.user-comment').find('.comment-body').text() }

        this.updateComment(url, { user_comment: payload },  (comment) => {
          $(e.target).text('Edit')
          $(e.target).closest('.user-comment').find('.comment-updated-at').text(comment.updated_at_ago).attr('contenteditable','false').attr('title', comment.updated_at)
          $(e.target).closest('.user-comment').find('.comment-body').attr('contenteditable','false')
          $(e.target).closest('.user-comment').prependTo('.user-comments-tab .user-comments')
        })
      }
    })

    $('.user-comments-tab .add-comment').on('click', e => {
      e.preventDefault()

      $(".comment-form-container").toggle()
    })

    $('.comment-form-container textarea').keydown(function (e) {
      if ((e.ctrlKey || e.metaKey) && (e.keyCode == 13 || e.keyCode == 10)) {
        $(this).parents('.comment-form-container').find('.save-comment').trigger('click');
      }
    })

    $('.comment-form-container .save-comment').on('click', e => {
      e.preventDefault()
      const $this = $(e.target)
      const url = $this.attr('href')
      const payload = {
        user_id: $this.data('user-id'),
        body: $this.closest('.comment-form-container').find('textarea').val()
      }

      this.createComment(url, {user_comment: payload}, (comment) => {
        const commentHtml = `<div class="user-comment"><div class="comment-header"><span>${comment.creator_name}</span> | &nbsp;<span class="comment-updated-at" title="${comment.updated_at}">${comment.updated_at_ago}</span></div><div class="row"><div class="col-md-9"><p class="comment-body">${comment.body}</p></div><div class="col-md-3 d-flex"><a class="edit-comment" href="/user-comments/${comment.id}">Edit</a>&nbsp;&nbsp;<a class="delete-comment text-danger" href="/user-comments/${comment.id}">Delete</a></div></div></div>`
        
        $('.comment-form-container textarea').val('')
        $(".comment-form-container").hide()
        $('.user-comments-tab .user-comments').prepend(commentHtml)
      })
    })
  },

  createComment(url, payload, callback) {
    let token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      url: url,
      type: 'POST',
      data: payload,
      beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
      success: (data) => {
        callback(data)
      },
      error: function (xhr, status, error) {
        alert(error)
      }
    })
  },

  updateComment(url, payload, callback) {
    let token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      url: url,
      type: 'PUT',
      data: payload,
      beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
      success: (data) => {
        callback(data)
      },
      error: function (xhr, status, error) {
        alert(error)
      }
    })
  },

  deleteComment(url, callback) {
    let token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      url: url,
      type: 'DELETE',
      data: {},
      beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
      success: (data) => {
        callback(data)
      },
      error: function (xhr, status, error) {
        alert(error)
      }
    })
  },
}
