export const config = {

  timeslot: {
    format: 'start', // can be range or start
  },

  // currency: {
  //   code: process.env.REACT_APP_FHUB_CURRENCY_CODE, // SEK, USD
  //   format: process.env.REACT_APP_CURRENCY_FORMAT,
  // },

  // limits: {
  //   maxAvail: 99,
  // },

  // paymentOptions: {
  //   gc: {
  //     enabled: process.env.REACT_APP_GC_ENABLED == "true",
  //   }
  // },

  //
  // urls: {
  //   membersApp: process.env.REACT_APP_MEMBERS_APP_URL,
  // },

  // send_receipt: (process.env.REACT_APP_SEND_RECEIPT || "false") == "true",

  // defaultToCurrentDateRange: () => {
  //   // only on 8th of march 2021
  //   return (new Date()).toISOString().substr(0,10) == "2021-03-08";
  // }

  // showDiscounts: false,
}
