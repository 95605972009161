import React from "react";
import * as Yup from "yup";
import DataField from "./controls/DataField";
import DataFieldArray from "./controls/DataFieldArray";
import DataForm from "./controls/DataForm";

const AppDataEditor = ({ value, onDataReset, onDataSubmit, storagePath }) => {
  const defaultValues = {
    sharingProhibited: true,
  };

  const expectedFiles = [
    { filename: "icon.png", description: "Apple, 29x29 px" },
    { filename: "icon@2x.png", description: "Apple, 58x58 px" },
    { filename: "icon@3x.png", description: "Apple, 87x87 px" },
  ];

  const validationSchema = Yup.object({
    organizationName: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    sharingProhibited: Yup.boolean(),
    locations: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required("Required"),
        relevantText: Yup.string().required("Required"),
        latitude: Yup.number().required("Required").max(90, "Must be between -90 and 90").min(-90, "Must be between -90 and 90"),
        longitude: Yup.number().required("Required").min(-180, "Must be between -180 and 180").max(180, "Must be between -180 and 180"),
      })
    ),
  });

  return (
    <DataForm
      value={value}
      onDataReset={onDataReset}
      onDataSubmit={onDataSubmit}
      storagePath={storagePath}
      defaultValues={defaultValues}
      expectedFiles={expectedFiles}
      validationSchema={validationSchema}
    >
      {(props) => (
        <React.Fragment>
          <DataField
            field="organizationName"
            type="text"
            label="Organization Name"
            placeholder="Organization Name"
          />
          <DataField
            field="description"
            type="text"
            label="Description"
            placeholder="Description"
          />
          <DataField
            field="sharingProhibited"
            type="checkbox"
            label="Sharing Prohibited"
          />

          <DataFieldArray
            modelSingular="Location"
            modelPlural="Locations"
            field="locations"
            values={props.values}
            newObject={{
              id: '',
              latitude: 0,
              longitude: 0,
              relevantText: '',
            }}
          >
            {({ prefix }) => (
              <React.Fragment>
                <div className="row">
                  <div className="col-12">
                    <DataField
                      field={`${prefix}id`}
                      type="text"
                      label="ID"
                      placeholder="unique location ID"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <DataField
                      field={`${prefix}relevantText`}
                      type="text"
                      label="Relevant text"
                      placeholder="location description"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6">
                        <DataField
                          field={`${prefix}latitude`}
                          type="number"
                          label="Latitude"
                          placeholder="location latitude"
                        />
                      </div>
                      <div className="col-6">
                        <DataField
                          field={`${prefix}longitude`}
                          type="number"
                          label="Longitude"
                          placeholder="location longitude"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </DataFieldArray>
        </React.Fragment>
      )}
    </DataForm>
  );
};

export default AppDataEditor;
