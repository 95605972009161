import React, {Component, useEffect, useState} from "react";

const Card = (props) => {

  return (
    <div className="card">
			<div className="card-header">
				<h5>{props.title}</h5>
			</div>
			<div className="card-body">
					{props.children}
			</div>
		</div>
  );

}

export default Card;