export default {
  initForm() {
    this.handleRestriction()
    this.handleConditions()
    this.handleRestrictedToMembers()
    $('#product-form').on('change', '#product_restricted', this.handleRestriction)
    $('#product-form').on('change', '#product_restricted_to_members', this.handleRestrictedToMembers)
  },

  handleRestrictedToMembers() {
    let $restricted = $('#product_restricted_to_members')
    let $visible_to_members = $('#product_visible_to_non_members').parents('.form-group')
    let $formGroup = $('#product_membership_types').parents('.form-group')
    let $checkboxes = $formGroup.find('input[type="checkbox"]')
    if ($restricted.is(':checked')) {
      $formGroup.show()
      $visible_to_members.show()
      $checkboxes.prop('disabled', false)
    } else {
      $formGroup.hide()
      $visible_to_members.hide()
      $checkboxes.prop('disabled', true)
      $checkboxes.prop('checked', false)
    }
  },

  handleRestriction() {
    let $restricted = $('#product_restricted')
    let $formGroup = $('#product_apps').parents('.form-group')
    let $checkboxes = $formGroup.find('input[type="checkbox"]')
    if ($restricted.is(':checked')) {
      $formGroup.show()
      $checkboxes.prop('disabled', false)
    } else {
      $formGroup.hide()
      $checkboxes.prop('disabled', true)
      $checkboxes.prop('checked', false)
    }
  },

  handleConditions () {    
    this.showOrHideConditions()
    $('body').on('group:add', '#has-one-container-product_product_restriction', (e) => {
      this.showOrHideConditions()
    })
    $('body').on('change', '#product_product_restriction_condition_type', (e) => {
      this.showOrHideConditions()
    })
    $('body').on('group:add', '#has-many-container-product_product_restriction_conditions', (e, newGroup) => {
      this.handleConditionSelect(newGroup)
    })
    let groups = $('#has-many-container-product_product_restriction_conditions').find('.alkemist_hm--group')
    $.each(groups, (index, group) => {
      this.handleConditionSelect(group)
      this.addRemoveHandles(group)
    })
    
  },

  showOrHideConditions () {
    let $conditionType = $('#product_product_restriction_condition_type')
    let $conditionContainer = $conditionType.parents('.alkemist_ho--container').find('.alkemist_hm--container')

    if ($conditionType.val() == 'none') {
      $conditionContainer.hide()
    } else {
      $conditionContainer.show()
    }
  },

  addRemoveHandles(group) {
    let $group = $(group)
    $group.prepend('<a class="close" href="#">&times;</a>')
  },

  handleConditionSelect(group) {
    let $group = $(group)
    let $actor = $group.find('select[id$="actor"]')
    let $value = $group.find('input[id$="value"]')
    let $valueSelect = $group.find('select[id$="valueSelect"]')
    let data = JSON.parse($value.attr('data-values'))

    if ($valueSelect.length == 0) {
      let formGroup = $('<div class="form-group"><div class="input-group"><div class="input-group-prepend"><span class="input-group-text">Value</span></div></div></div>')
      $valueSelect = $('<select class="form-control" id="' + $actor.attr('id').replace('actor', 'valueSelect') + '"></select>')
      
      let options = data[$actor.val()]
      $valueSelect.html(this.generateOptions(options))      
      
      formGroup.find('.input-group').append($valueSelect)
      if ($value.val()) {
        $valueSelect.val($value.val())
      } else {
        if (!$valueSelect.val()) {
          $valueSelect.val(options[0])
        }
        $value.val($valueSelect.val())
      } 
      $group.append(formGroup)
    }
    $actor.on('change', (e) => {
      let options = data[$actor.val()]
      $valueSelect.html(this.generateOptions(data[$actor.val()]))
      $valueSelect.val(options[0])
      $value.val($valueSelect.val())
    })
    $valueSelect.on('change', (e) => {
      $value.val($valueSelect.val())
    })
  },

  generateOptions(data) {
    let options = []
    for (let i = 0; i < data.length; i++) {
      options.push(`<option value="${data[i]['value']}">${data[i]['label']}</option>`)
    }
    return options.join("\n")
  }
}
