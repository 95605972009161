import React, {Component, useEffect, useState} from "react";

const Form = ({children, onSubmit}) => {

  return (
    <form className="col-lg-8 edit-model-form" onSubmit={onSubmit}>{children}</form>
  );

}

export default Form;
