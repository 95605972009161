
require.register("regexp.prototype.flags/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "regexp.prototype.flags");
  (function() {
    'use strict';

var define = require('define-properties');
var callBind = require('call-bind');

var implementation = require('./implementation');
var getPolyfill = require('./polyfill');
var shim = require('./shim');

var flagsBound = callBind(getPolyfill());

define(flagsBound, {
	getPolyfill: getPolyfill,
	implementation: implementation,
	shim: shim
});

module.exports = flagsBound;
  })();
});