import React, {Component, useEffect, useState} from "react";
import Control from "./Control";
import useInput from "../hooks/useInput";

const Input = ({object, field, title, type="text", required = false, placeholder}) => {

	
	const { value: newValue, bind, reset } = useInput(object[field]);

	useEffect(() => {
    object[field] = newValue
  },[newValue])

  return (
    <Control title={title}>
			<input className="form-control" type={type} {...bind} required={required} placeholder={placeholder}></input>
		</Control>
  );

}

export default Input;