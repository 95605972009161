
require.register("date-fns/_lib/cloneObject/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "date-fns");
  (function() {
    "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = cloneObject;
var _index = _interopRequireDefault(require("../assign/index.js"));
function cloneObject(object) {
  return (0, _index.default)({}, object);
}
module.exports = exports.default;
  })();
});