import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import FilesInput from "./FilesInput";

const DataForm = ({
  expectedFiles,
  value,
  onDataReset,
  onDataSubmit,
  storagePath,
  validationSchema,
  defaultValues = {},
  children,
}) => {
  const [uploadTrigger, setUploadTrigger] = useState(0);
  const [values, setValues] = useState(undefined);
  const triggerUpload = () => {
    setUploadTrigger((uploadTrigger) => uploadTrigger + 1);
  };

  const onFilesUploaded = (files) => {
    onDataSubmit({
      ...Object.fromEntries(
        Object.entries(values).filter(([key, value]) => value !== "")
      ),
      files,
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={value || defaultValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (expectedFiles && expectedFiles.length>0) {
          setValues(values);
          triggerUpload();
        } else {
          onDataSubmit({
            ...Object.fromEntries(
              Object.entries(values).filter(([key, value]) => value !== "")
            ),
          });
        }
      }}
      onReset={(values, { setSubmitting }) => {
        onDataReset();
      }}
    >
      {(props) => {
        const { values, setFieldValue } = props;
        return (
          <Form>
            {typeof children == "function" ? children(props) : children}

            {expectedFiles && expectedFiles.length > 0 && (
              <div className="card">
                <div className="card-header">
                  <h5>Files</h5>
                </div>
                <div className="card-body">
                  <FilesInput
                    expectedFiles={expectedFiles}
                    files={values && values.files}
                    storagePath={storagePath}
                    uploadTrigger={uploadTrigger}
                    onFilesUploaded={onFilesUploaded}
                  />
                  <ErrorMessage name="files" />
                </div>
              </div>
            )}

            <div className="text-right">
              <button type="reset" className="btn btn-secondary mr-2">
                Reset
              </button>
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DataForm;
