
require.register("@hypnosphi/create-react-context/lib/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "@hypnosphi/create-react-context");
  (function() {
    'use strict';

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _implementation = require('./implementation');

var _implementation2 = _interopRequireDefault(_implementation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _react2.default.createContext || _implementation2.default;
module.exports = exports['default'];
  })();
});