import {render, unmountComponentAtNode} from "react-dom";

export const initReactAppTriggers = function (appName, callback) {
  const triggerElems = document.querySelectorAll(`[data-app="${appName}"]`);
  if (triggerElems.length>0) {
    for (let triggerElem of triggerElems) {
      triggerElem.addEventListener('click', function(e) {
        e.preventDefault();
        const data = mapDataset(triggerElem.dataset);
        const jsx = callback(data)
        let appContainer = document.querySelector(`[data-app-container="${appName}"]`)
        if (!appContainer) {
          appContainer = document.createElement('div');
          appContainer.setAttribute("data-app-container", appName);
          document.body.appendChild(appContainer);
        } else {
          unmountComponentAtNode(appContainer);
        }
        render(jsx, appContainer);
      })
    }
  }
}

export const initReactApp = function (appName, callback) {
  const appContainer = document.querySelector(`[data-app-container="${appName}"]`)
  // if (!appContainer) {
  //   appContainer = document.createElement('div');
  //   appContainer.setAttribute("data-app-container", appName);
  //   document.body.appendChild(appContainer);
  // } else {
  //   unmountComponentAtNode(appContainer);
  // }
  if (appContainer) {
    unmountComponentAtNode(appContainer);
    const data = mapDataset(appContainer.dataset);
    const jsx = callback(data)
    render(jsx, appContainer);
    return true;
  } else {
    return false;
  }
}

function mapDataset(dataset) {
  const data = {};
  for (let param in dataset) {
    let val = dataset[param];
    if (val == "true") {
      val = true;
    }
    if (val == "false") {
      val = false;
    }
    if (val == "null") {
      val = null;
    }
    data[param] = val;
  }
  return data;
}