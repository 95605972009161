import React, {useEffect, useState} from "react";
import {TimeslotControl} from "./TimeslotControl";
import TimeslotGroupApi from "../../api/TimeslotGroupApi";
import {formatTimeslotDate, formatTimeslotTimeRange} from "../../lib/purchase/timeslot";
import {LineItemTickets} from "./LineItemTickets";

const fetchTimeslotGroup = async (timeslotGroupId) => {
  return timeslotGroupId ? await TimeslotGroupApi.get(timeslotGroupId) : null;
}

export function LineItemRow({lineItem, selected, onSelect, onChange, editMode, timeFormat, version}) {
  const {totalPrice, productPrice, totalNetPrice, productNetPrice, priceType, product, quantity} = lineItem;

  const [timeslotGroup, setTimeslotGroup] = useState();

  useEffect(async () => {
    const timeslotGroupId = product && product.ticketType && product.ticketType.timeslotGroupId;
    setTimeslotGroup(await fetchTimeslotGroup(timeslotGroupId));
  }, [version]);


  // const [timeslot, setTimeslot] = useState(lineItem.timeslot);


  const onSelectChange = (e) => {
    onSelect(e.target.checked)
  };

  const onTimeslotChange = (timeslot) => {
    if (timeslot) {
      // setTimeslot(timeslot)
      // onChange({timeslotId: timeslot && timeslot.id})
      onChange({timeslot: timeslot})
    }
  }

  function countUnusedTicketUsages() {
    return lineItem.tickets
      .filter(ticket => ticket.status == 'unused')
      .map(ticket => ticket.numUsages)
      .reduce((acc, i) => acc + i, 0);
  }

  return <tr>
    {editMode &&
      <td className="select">
        <input type="checkbox" checked={selected} onChange={onSelectChange}/>
      </td>
    }
    <td className="title">
      <a href={`/line_items/${lineItem.id}`}><strong>{lineItem.id}</strong></a>
    </td>
    <td className="title">
      <a href={`/products/${product.id}`}><strong>{product.name}</strong></a>
    </td>
    <td className="tickets">
      <LineItemTickets tickets={lineItem.tickets} timeFormat={timeFormat}/>
    </td>
    <td className="timeslot">
      {lineItem.timeslot && editMode &&
        <TimeslotControl onChange={onTimeslotChange}
                         timeslot={lineItem.timeslot}
                         timeslotGroup={timeslotGroup}
                         timeFormat={timeFormat}
                         numUsages={countUnusedTicketUsages()}
        />
      }
      {lineItem.timeslot && !editMode &&
        <a
          href={`/timeslots/${lineItem.timeslot.id}`}>{formatTimeslotDate(lineItem.timeslot.timeslotDate) + " " + formatTimeslotTimeRange(lineItem.timeslot, timeFormat)}</a>
      }
    </td>
    <td className="price-type">
      {priceType}
    </td>
    <td className="quantity">
      <span>{quantity}</span>
    </td>
    <td className="price text-muted">
      {productNetPrice}
    </td>
    <td className="price">
      {productPrice}
    </td>
    <td className="total text-muted">
      {totalNetPrice}
    </td>
    <td className="total">
      {totalPrice}
    </td>
  </tr>;
}