export default {
  initForm() {
    $('#ticket_type-form').on('blur', '.alkemist_hm--group input', function (e) {
      let $target = $(e.target)
      let name = $target.attr('name')

      if (name.indexOf('timeslot_start') > -1 && !$target.val()) {
        $target.val('00:00:00.0000')
      } else if (name.indexOf('timeslot_end') > -1 && !$target.val()) {
        $target.val('23:59:59.9999')
      }
    })
  }
}