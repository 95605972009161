import {render} from "react-dom";
import React from "react";
import equalWidth from "../equalWidth";

import DiscountRuleForm from "../containers/discountRule/DiscountRuleForm";
import {roundPercent, roundPrice, formatPrice, parsePercent} from '../lib/discountRule/utils';

class DiscountCalculatorProduct {
  constructor({elem, onValidChange, onApply}) {
    this.onApply = onApply;
    this.elem = elem;
    this.onValidChange = onValidChange;
    this.price = parseFloat(this.elem.dataset.price);
    this.listen();

  }

  
  listen() {
    this.elem.addEventListener('keydown', (event) => {
      if (event.which == 13 || event.keyCode == 13) {
        event.preventDefault();
        this.onApply && this.onApply();
      } else {
        setTimeout( this.changed.bind(this), 10)
      }
    } );
    this.elem.addEventListener('change', this.changed.bind(this));
  }

  changed() {
    if (this.isValid()) {
      this.onValidChange && this.onValidChange(this);
    }
  }

  isValid() {
    return this.price!=0 && (/^\d+$/.test(this.elem.value));
  }

  calcPercent() {
    return roundPercent((parseFloat(this.elem.value)/this.price - 1.0) * 100.0);
  }

  setPercent(percent) {
    const discountedPrice = roundPrice(this.price * ( 100.0 + percent ) / 100.0);
    this.elem.value = formatPrice(discountedPrice);
  }
}

class DiscountCalculatorPercent {
  constructor({elem, onValidChange, onApply}) {
    this.elem = elem;
    this.onApply = onApply;
    this.onValidChange = onValidChange;
    this.listen();

  }
  
  listen() {
    this.elem.addEventListener('keydown', (event) => {
      if (event.which == 13 || event.keyCode == 13) {
        event.preventDefault();
        this.onApply && this.onApply();
      } else {
        setTimeout( this.changed.bind(this), 10)
      }
    } );
    this.elem.addEventListener('change', this.changed.bind(this));
  }

  changed() {
    if (this.isValid()) {
      setTimeout( () => {        
        this.onValidChange && this.onValidChange(this);
      }, 100);
    }
  }

  isValid() {
    return /^[-+]?\d+(\.\d+)?$/.test(this.elem.value) && parseFloat(this.elem.value) >= -100.0 && parseFloat(this.elem.value)<=10000.0;
  }

  getPercent() {
    return parsePercent(this.elem.value);
  }

  setPercent(percent) {
    this.elem.value = formatPercent(percent);    
  }
}

class DiscountCalculatorUI {
  constructor({button, modal, getTimeslotGroupId, getPercentCallback, setPercentCallback}) {
    this.setPercentCallback = setPercentCallback;
    this.getPercentCallback = getPercentCallback;
    this.getTimeslotGroupId = getTimeslotGroupId;
    this.button = button;
    this.modal = modal;
    this.parent = this.modal.querySelector('.modal-body');
    this.apply = this.modal.querySelector('*[data-apply]');
    if (this.button) {
      this.button.addEventListener('click', this.show.bind(this));
    }    
    if (this.apply) {
      this.apply.addEventListener('click', this.onApply.bind(this));
    }
  }

  canApply() {
    return this.percent.isValid();
  }

  onApply() {
    if (this.canApply()) {
      this.setPercentCallback(this.percent.getPercent());
      $(this.modal).modal('hide');
    } else {
      alert("Percent is not valid. Must be a number between -100 and 10000");
    }
  }

  show() {
    this.updateProducts(this.getTimeslotGroupId()).then(() => {
      this.init();
      $(this.modal).modal('show');
    }).catch(error => {
      alert(error)
    });
  }

  updateProducts(timeslot_group_id) {
    return new Promise((resolve, reject) => {
      const token = $('meta[name="csrf-token"]').attr('content')
      $.ajax({
        url: `/discount_rules/calculator`,
        type: 'POST',
        data: {timeslot_group_id: timeslot_group_id},
        beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
        success: (data) => {
          this.parent.innerHTML= data;
          resolve();  
        },
        error: function(xhr, status, error) {
          reject(error);
        }
      })
    })    
  }

  init() {
    this.products = [...this.parent.querySelectorAll('*[data-discount-rule-calculator="discounted_price"]')].map(
      elem => new DiscountCalculatorProduct({
        elem, 
        onValidChange: this.onProductChanged.bind(this),
        onApply: () => this.canApply() && this.onApply(),
      })
      ) 
    this.percentElem = this.parent.querySelector('*[data-discount-rule-calculator="calculated_percent"]');
    this.percent = new DiscountCalculatorPercent({
      elem: this.percentElem, 
      onValidChange: this.onPercentChange.bind(this),
      onApply: () => this.canApply() && this.onApply(),
    });
    let percentVal = parsePercent(this.getPercentCallback());
    if (percentVal==null) {
      percentVal = 0;
    }
    this.percent.setPercent(percentVal);
    this.percent.changed();
  }

  onPercentChange(percent) {
    const percentVal = percent.getPercent();
    for (let product of this.products) {
      product.setPercent(percentVal);
    }
    // this.percent.setPercent(percentVal);
  } 

  onProductChanged(changedProduct) {
    const percent = changedProduct.calcPercent();
    this.percent.setPercent(percent);
    for (let aProduct of this.products) {
      if (aProduct == changedProduct) {

      } else {
        aProduct.setPercent(percent);
      }
    }
  }


}
export default {
  initForm() {
    // this.handleRestriction()
    // $('#discount_rule-form').on('change', '#discount_rule_restricted', this.handleRestriction)
    // this.initCalculator();
    this.initReactForm();
  },

  initReactForm() {
    const container = document.getElementById('discount_rule-form');
    if (container) {
        render(<DiscountRuleForm {...container.dataset}/>, container);        
    }
  },

  handleRestriction() {
    let $restricted = $('#discount_rule_restricted')
    let $formGroup = $('#discount_rule_products').parents('.form-group')
    let $checkboxes = $formGroup.find('input[type="checkbox"]')
    if ($restricted.is(':checked')) {
      $formGroup.show()
      $checkboxes.prop('disabled', false)
    } else {
      $formGroup.hide()
      $checkboxes.prop('disabled', true)
      $checkboxes.prop('checked', false)
    }
  },

  initCalculator() {
    this.discountCalculator = new DiscountCalculatorUI({
      getTimeslotGroupId: () => document.querySelector('#discount_rule_timeslot_group_id').value,      
      button: document.querySelector('*[data-discount-rule-calculator]'),
      modal: document.querySelector('#discount_rule_calculator_modal'),
      getPercentCallback: () => document.querySelector('#discount_rule_percent').value, 
      setPercentCallback: (percent) => document.querySelector('#discount_rule_percent').value = percent, 
    }); 
    
  }
  
}
