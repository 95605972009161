import {apiRequest} from "../lib/api";

const PURCHASE_FIELDS = `
fragment PurchaseFields on Purchase{
  branch {
    id
    config {
      timeFormat
      timeslotFormat
      timezone
    }
  }
  currency
  id

  lineItems {
    id
    product {
      id
      name
      ticketType {
        id
        timeslotGroupId
        timeslotGroup {
          id
        }
      }
    }
    timeslot {
      id
      timeslotDate
      timeslotStart
      timeslotEnd
      leftUsages
      datetime
    }
    tickets {
      id
      status
      numUsages
      timeslots {
        id
        valid
        timeslotDate
        timeslotStart
        timeslotEnd
        datetime
      }
    }
    productNetPrice
    productPrice
    priceType
    totalNetPrice
    totalPrice
    quantity
  }
  netPrice
  price
  user {
    id
  }
  coupon {
    id
    name
  }
}
`;

export default class PurchaseApi {

  static async get(id) {
    const response = await apiRequest({
      url: "/graph",
      method: "POST",
      data: {
        "operationName": "getPurchase",
        "query": `
        ${PURCHASE_FIELDS}
        query getPurchase($purchaseId: Int!) {
          purchase(id: $purchaseId){
            ...PurchaseFields
          }
        }
      `,
        "variables": {
          purchaseId: id,
        }
      },
    })
    return response.data.data && response.data.data.purchase;
  }

  static async update(id, data) {
    const response = await apiRequest({
      url: "/graph",
      method: "POST",
      data: {
        "operationName": "updatePurchase",
        "query": `
        ${PURCHASE_FIELDS}
        input UpdateLineItemParams {
          id: Int!
          timeslotId: Int
        }
        input UpdatePurchaseParams {
          sendTickets: Boolean
          lineItems: [UpdateLineItemParams]
        }
        mutation updatePurchase($purchaseId: Int!, $purchase: UpdatePurchaseParams) {
          updatePurchase(id: $purchaseId, purchase: $purchase){
            ...PurchaseFields
          }
        }
      `,
        "variables": {
          purchaseId: id,
          purchase: data,
        }
      },
    })
    return response.data.data && response.data.data.updatePurchase;
  }

}