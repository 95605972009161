import React from "react";
import equalWidth from "../equalWidth";

import InlinePurchaseApp from "../containers/purchase/InlinePurchaseApp";
import { initReactApp } from "../lib/reactApp";



export default {
  initForm() {
    const appName = 'inlinePurchaseApp';    
    initReactApp('inlinePurchaseApp', (dataset) => {
      return <InlinePurchaseApp {...dataset}/>;
    });

  },  
}
