export default {
  initForm() {

    $("#timeslot_group-form #timeslot_group_timeslot_generator_unlock_schedule").on("click", function() {
      var allowEdit = $(this).prop('checked');
      var inputs = $(this).parents('.card-body').find('input[type=text]')
      if (allowEdit) {
        inputs.removeAttr('disabled')
      } else {
        inputs.attr('disabled', true);
      }
    });
  }
}
