// custom functions for user form
import intlTelInput from 'intl-tel-input';

export default {
  last_enabled_branch: null,
  branches: null,
  default_branch: null,

  init: function () {
    const forms = {
      ".allow-edit[data-rel=details]": this.initDetails,
      ".allow-edit[data-rel=details-admin]": this.initDetailsAdmin,
    };

    Object.entries(forms).forEach(([sel, initCallback]) => {

      document
        .querySelector(sel)
        .addEventListener("tabbedShowFormUpdated", () => {
          initCallback.bind(this)();
        });
    });

    Object.entries(forms).forEach(([_, initCallback]) => {
      initCallback.call(this);
    });
  },

  initDetailsAdmin: function() {
    this.branches = document.querySelectorAll('*[name="user[branches][]"]');
    this.default_branch = document.querySelector('#user_default_branch_id');
    this.branches.forEach(i => i.addEventListener('change', () => this.handleBranchChange(null)))
    this.default_branch.addEventListener('change', () => this.handleBranchChange(this.last_enabled_branch))
  },

  initDetails: function () {

    let input = document.querySelector("#user_phone");
    const initial_country_code = input.dataset.initialCountryCode
    const preferred_country_codes = (input.dataset.preferredCountryCodes && JSON.parse(input.dataset.preferredCountryCodes)) || []


    // init span for ERROR message next to tel input
    let errorMsg = document.createElement("span");
    errorMsg.className = "error hidden"
    input.parentNode.insertBefore(errorMsg, input.nextSibling);

    // init span for VALID message next to tel input
    let validMsg = document.createElement("span");
    validMsg.innerHTML = "✓"
    validMsg.className = "success hidden"
    validMsg.setAttribute("style", "display: none;")
    input.parentNode.insertBefore(validMsg, input.nextSibling);

    // initialise plugin
    let iti = intlTelInput(input, {
      initialCountry: initial_country_code,
      nationalMode: false,
      autoFormat: true,
      formatOnDisplay: true,
      separateDialCode: true,
      autoInsertDialCode: true,
      autoPlaceholder: "aggressive",
      // initialCountry: "auto",
      placeholderNumberType: "MOBILE",
      preferredCountries: preferred_country_codes,
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.19/build/js/utils.js"
    });


    const errorMap = {
      IS_POSSIBLE: "phone number doesn't look valid",
      INVALID_COUNTRY_CODE: "invalid country code",
      TOO_SHORT: "phone number too short",
      TOO_LONG: "phone number too long",
      IS_POSSIBLE_LOCAL_ONLY: "phone number is a possible local number",
      INVALID_LENGTH: "invalid length",
    };

    function formatError(code) {
      const foundEntry = Object.entries(intlTelInputUtils.validationError).find((k,v) => v == code);
      if (foundEntry!==undefined) {
        return errorMap[foundEntry[0]]
      } else if (code == -99) {
        return "Invalid phone number";
      } else {
        return "Invalid phone number (unknown)";
      }
    }

    const parentForm = $(input).parents('form')[0];


    parentForm.addEventListener('beforeSubmit.tabbedShow', () => {
      if (iti.isValidNumber()) {
        input.value = iti.getNumber();
      }
    })

    const isValid = () => {
      return iti.isValidNumber() || iti.getNumber() == '';
    }

    const reset = () => {
      const saveButton = document.querySelector(".save");

      if (saveButton) {
        // saveButton.disabled = false;
        // saveButton.classList.remove('disabled');
      }
      errorMsg.innerHTML = "";
      input.classList.remove("error");
      errorMsg.classList.add("hidden");
      validMsg.setAttribute("style", "display: none");
    }

    const updateValidStatus = () => {
      const saveButton = document.querySelector(".save");

      const empty = iti.getNumber() == '';
      const valid = isValid();
      validMsg.setAttribute("style", valid && !empty ? "" : "display: none");
      if (saveButton) {
        // saveButton.disabled = !valid;
      }
      if (valid) {
        if (saveButton) {
          // saveButton.classList.remove('disabled');
        }
        errorMsg.innerHTML = "";
        input.classList.remove("error");
        errorMsg.classList.add("hidden");
      } else {
        if (saveButton) {
          // saveButton.classList.add('disabled');
        }
      }
    }

    const updateErrorMessage = () => {
      const valid = isValid();
      if (!valid) {
        var errorCode = iti.getValidationError();
        errorMsg.innerHTML = formatError(errorCode);
        input.classList.add("error");
        errorMsg.classList.remove("hidden");
      } else {
        errorMsg.innerHTML = "";
        input.classList.remove("error");
        errorMsg.classList.add("hidden");
      }
    }

    const onInputChange = () => {
      updateValidStatus();
      updateErrorMessage();
    }

    input.addEventListener('blur', onInputChange);
    input.addEventListener('change', onInputChange);
    input.addEventListener('keyup', onInputChange);
  },

  handleBranchChange: function (lastEnabledBranch) {
    let branch_id = this.default_branch.value
    if (lastEnabledBranch && branch_id!=lastEnabledBranch) {
      this.branches.forEach((branch) => {
        if (branch.value == lastEnabledBranch) {
          branch.checked = false;
        }
      })
    }
    this.branches.forEach((branch) => {
      if (branch.value == branch_id && !branch.checked) {
        branch.checked =  true;
        this.last_enabled_branch = branch.value;
      }
    })
  }
}