export default {
  init() { 
    $('#messages-table').on('click', '.send-message', (e) => {
      e.preventDefault()
      let id = $(e.target).attr('data-rel')

      this.sendMessage(id, (message) => {
        alert(message)
        location.reload()
      })
    })
  },



  sendMessage(id, callback) {
    let token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      url: `/messages/${id}/send`,
      type: 'POST',
      data: {},
      beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
      success: (data) => {
        callback(data)
      },
      error: function(xhr, status, error) {
        alert(error)
      }
    })
  },
}