export default {
  init() { 
    $('#purchase-tickets').on('click', '.send-tickets', (e) => {
      e.preventDefault()
      const url = $(e.target).attr('href')

      this.sendMessage(url, (message) => {
        alert(message)
      })
    })
  },



  sendMessage(url, callback) {
    let token = $('meta[name="csrf-token"]').attr('content')
    $.ajax({
      url: url,
      type: 'POST',
      data: {},
      beforeSend: function (xhr) { xhr.setRequestHeader("x-csrf-token", token) },
      success: (data) => {
        callback(data)
      },
      error: function(xhr, status, error) {
        alert(error)
      }
    })
  },
}
