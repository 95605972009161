
export default {
  init() {    
    const dateFilterGroups = document.querySelectorAll('.date-filter-group')
    for (let filterGroup of [...dateFilterGroups]) {
      this.initFilterGroup(filterGroup)
    }
  },

  initFilterGroup(filterGroup) {
    const selectElem = filterGroup.querySelector('select')
    const rangeInputs = filterGroup.querySelectorAll('input')
    selectElem.addEventListener('change', (e) => {
      for (let input of [...rangeInputs]) {
        if (e.target.value!='') {
          input.value = '';
        }
      }
    })

    for (let input of [...rangeInputs]) {
      input.addEventListener('change', (e) => {
        if (e.target.value!='') {
          selectElem.value='';
        }
      })

      $(input).on('pick.datepicker', (e) => {
        selectElem.value='';
      })

      $(input).on('show.datepicker', (e) => {
        selectElem.value='';
      })

    }
  }
 
}
