import React from "react";
import BranchDataEditor from "./BranchDataEditor";
import AppDataEditor from "./AppDataEditor";
import MembershipTypeDataEditor from "./MembershipTypeDataEditor";

const WalletPassDataEditor = (props) => {
  const { object } = props;
  return (
    <div>
      {object === "branch" && <BranchDataEditor {...props} />}
      {object === "membership_type" && <MembershipTypeDataEditor {...props} />}
      {object === "app" && <AppDataEditor {...props} />}
    </div>
  );
};

export default WalletPassDataEditor;
