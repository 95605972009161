
  export const formatDate = date => {
    if (!date) {
      return null;
    }
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }


  export const formatTime = date => {
    if (!date) {
      return null;
    }
    var d = new Date(date),
        hour = '' + (d.getHours()),
        minute = '' + d.getMinutes(),
        second = '' + d.getSeconds();

    if (minute.length < 2) 
      minute = '0' + minute;
    if (second.length < 2) 
      second = '0' + second;
    if (hour.length < 2) 
      hour = '0' + hour;

    if (minute == "59" && hour == "23") {
      second = 59;
    }

    return [hour, minute, second].join(':');
  }


  export const parseTime = (time) => {
    const d = new Date(formatDate(new Date()) + "T" + time);
    return d;
  }


  export const calcDiscountedPrice = (origPrice, valueType, value) => {
    if (value !== null && value != undefined) {
      switch (valueType) {
        case 'percent': return roundPrice(origPrice * (100.0 + value) / 100.0);
        case 'fixed': return roundPrice(parseFloat(origPrice) + value);
        case 'price': return roundPrice(value);
      }
    } else {
      return null;
    }
  }


  export function roundPercent(percent) {
    return Math.round((percent + Number.EPSILON) * 100) / 100;
  }
  
  export function roundPrice(price) {
    return Math.round(price + Number.EPSILON); // rounded to integer
  }
  
  export function formatPercent(price) {
    const v = Math.round((price + Number.EPSILON) * 100) / 100;
    const invVal = Math.round(price + Number.EPSILON);
    if (Math.abs(v - invVal) <= Number.EPSILON) {
      return invVal;
    } else {
      return v.toFixed(2);
    }
  }
  
  export function formatPrice(price) {
    const v = Math.round((price + Number.EPSILON) * 100) / 100;
    const invVal = Math.round(price + Number.EPSILON);
    if (Math.abs(v - invVal) <= Number.EPSILON) {
      return invVal;
    } else {
      return v.toFixed(2);
    }
  }
  
  export function parsePercent(str) {
    if (str=="" || str==null) {
      return null;
    }
    return roundPercent(parseFloat(str));
  }
  

  export function formatOrdinalNumber(i) {
    if (i == null || isNaN(i)) {
      return i;
    }
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}