import { FieldArray } from "formik";
import React from "react";

const DataFieldArray = ({
  modelSingular,
  modelPlural,
  field,
  values,
  newObject,
  children,
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <h5>{modelPlural}</h5>
      </div>
      <div className="card-body">
        <FieldArray
          name={field}
          render={({ insert, remove, push }) => (
            <div className="w-full">
              {values &&
                values[field] &&
                values[field].length > 0 &&
                ((values && values[field]) || []).map((_itemValue, index) => (
                  <div
                    key={index}
                    className="pt-4 pb-4 border-bottom border-secondary"
                  >
                    <div className="">
                      {children({ prefix: `${field}.${index}.` })}
                      <div className="row">
                        <div className="col-12 text-right">
                          <button
                            type="button"
                            className="btn btn-sm btn-secondary"
                            onClick={() => remove(index)}
                          >
                            Delete {modelSingular}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {(!values || !values[field] || values[field].length === 0) && (
                <div className="text-secondary">No {modelPlural}</div>
              )}
              <div className="pt-4">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={() =>
                    push(
                      typeof newObject === "object" ? newObject : newObject()
                    )
                  }
                >
                  Add {modelSingular}
                </button>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default DataFieldArray;
