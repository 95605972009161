import {apiRequest} from "../lib/api";

export default class WalletPassApi {

  static async createUpload({ path, filename }) {
    const response = await apiRequest({
      url: "/graph",
      method: "POST",
      data: {
        "operationName": "createWalletPassUpload",
        "query": `
        mutation createWalletPassUpload($path: String!, $filename: String!) {
          createWalletPassUpload(path: $path, filename: $filename){
            url
            key
            path
            filename
          }
        }
      `,
        "variables": {
          'filename': filename,
          'path': path,
        }
      },
    })
    return response.data.data && response.data.data.createWalletPassUpload;
  }

  static async getUpload({ path, filename }) {
    const response = await apiRequest({
      url: "/graph",
      method: "POST",
      data: {
        "operationName": "getWalletPassUpload",
        "query": `
        mutation getWalletPassUpload($path: String!, $filename: String!) {
          getWalletPassUpload(path: $path, filename: $filename){
            url
            key
            path
            filename
          }
        }
      `,
        "variables": {
          'filename': filename,
          'path': path,
        }
      },
    })
    return response.data.data && response.data.data.getWalletPassUpload;
  }

}