import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Card from "../../components/Card";
import Control from "../../components/Control";
import Form from "../../components/Form";
import FormActions from "../../components/FormActions";
import equalWidth from "../../equalWidth";
import { axiosInstance, convertModelToFormData } from "../../lib/axios";
import { calcDiscountedPrice, formatDate, formatTime, parsePercent, parseTime, roundPrice, formatOrdinalNumber } from "../../lib/discountRule/utils";


const registerRequired = { required: "field is required" };

const DatepickerCustomInput = ({ date, onChangeCustom }) => {
  return (
    
    <input
      type="time"
      step="1"
      value={value}
      onChange={e => onChangeCustom(date, e.target.value)}
    />
  )
};

const DiscountDetailsCard = ({ register, errors, value_type_options }) => {
  return (
    <Card title="Discount Details">
      <Control title="Product specific">
        <div className="form-control checkboxes">
          <div className="custom-control custom-checkbox">
            <input className="custom-control-input"
              type="checkbox"
              name="restricted"
              ref={register({})}
              id="restricted" />
            <label className="custom-control-label" htmlFor="restricted">yes</label>
          </div>
        </div>
      </Control>

      <Control title="Discount type"
        error={errors.value_type && errors.value_type.message }>
        <select className="form-control"
          name="value_type"
          ref={register(registerRequired)}>
          <option value={""}>Select</option>
          {value_type_options.map(({ value, label }) => <option value={value} key={value}>{label}</option>)}
        </select>
      </Control>
    </Card>
  )
}

const AdditionalDetailsCard = ({ register, errors, status_options }) => {
  return (
    <Card title="Additional">

      <Control title="Status"
        error={errors.status && errors.status.message}>
        <select className="form-control"
          name="status"
          ref={register(registerRequired)}>
          <option value={""}>Select</option>
          {status_options.map(({ value, label }) => <option value={value} key={value}>{label}</option>)}
        </select>
      </Control>

      <Control title="Priority"
        error={errors.priority && errors.priority.message}>
        <input className="form-control"
          name="priority"
          type="number"
          ref={register(registerRequired)} />
      </Control>
    </Card>

  )
}

const DiscountValueCard = ({ register, errors, valueType, valueTypeLabel, valueTypeDescription, branch }) => {
  return (
    <Card title={"All products " + valueTypeLabel}>
      <Control title={valueTypeLabel}
        description={valueTypeDescription}
        error={errors.value && errors.value.message}>
        <input className="form-control"
          name="value"
          step="0.01"
          type="number"
          ref={register(getValidationOptsByValueType(valueType))} />
      </Control>
    </Card>
  )
}

const DiscountScheduleCard = ({ control, repeat, discountDate, errors, register, repeat_options }) => {

  const getRepeatDescription = () => { 
    switch (repeat) {
      case 'never': return null;
      case 'daily': 
        if (discountDate) {
          const datePart = discountDate.toISOString().slice(0, 10);
          return `repeats each day since ${datePart}`;
        } else {
          return null;
        }
      case 'weekly': 
        if (discountDate) {
          const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
          const date = discountDate;
          const weekDay = days[ discountDate.getDay() ];
          const datePart = discountDate.toISOString().slice(0, 10);
          return `repeats each ${weekDay} since ${datePart}`;
        } else {
          return null;
        }
      case 'monthly': 
        if (discountDate) {
          const datePart = discountDate.toISOString().slice(0, 10);
          return `repeats each month on ${formatOrdinalNumber(discountDate.getDate())} day since ${datePart}`;
        } else {
          return null;
        }
      
      default: return null;
    }
  }

  return (<Card title="Discount Schedule">
    <Control title="Discount date"
      description="Discount is applied if timeslot happens on this date"
      error={errors.discount_date && errors.discount_date.message}>
      <Controller
        name="discount_date"
        control={control}
        render={({ onChange, value }) => <DatePicker wrapperClassName="form-control" popperPlacement="bottom-center" onChange={onChange} dateFormat="yyyy-MM-dd" selected={value} />}
        rules={registerRequired}
      />
    </Control>

    <Control title="Discount time since"
      description="Discount is applied if timeslots starts at or after this time"
      error={errors.discount_time_since && errors.discount_time_since.message}>
      <input className="form-control"
        name="discount_time_since"
        type="time"
        step={1}
        ref={register({ ...registerRequired, pattern: { value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/, message: "must be valid time HH:MM:SS"} })} />
    </Control>


    <Control title="Discount time until"
      description="Discount is applied if timeslots starts at or before this time"
      error={errors.discount_time_until && errors.discount_time_until.message}>
      <input className="form-control"
        name="discount_time_until"
        type="time"
        step={1}
        ref={register({ ...registerRequired, pattern: { value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/, message: "must be valid time HH:MM:SS"} })} />
    </Control>

    <Control title="Repeat"
      error={errors.repeat && errors.repeat.message}
      description={getRepeatDescription()}>
      <select className="form-control"
        name="repeat"
        ref={register(registerRequired)}>
        <option value={""}>Select</option>
        {repeat_options.map(({ value, label }) => <option value={value} key={value}>{label}</option>)}
      </select>
    </Control>

    {repeat && repeat != 'never' && repeat != '' &&
      <Control title="Repeat until"
        description="Optional"
        error={errors.repeat_until && errors.repeat_until.message}>
        <Controller
          name="repeat_until"
          control={control}
          render={({ onChange, value }) => <DatePicker wrapperClassName="form-control" popperPlacement="bottom-center" onChange={onChange} dateFormat="yyyy-MM-dd" selected={value} />}
          rules={{}}
        />
      </Control>
    }
  </Card>);
}


const TimeslotGroupCard = ({ errors, register, timeslot_group_options }) => {
  return (
    <Card title="Timeslot Group">

      <Control title="Timeslot Group"
        error={errors.timeslot_group_id && errors.timeslot_group_id.message}>
        <select className="form-control"
          name="timeslot_group_id"
          ref={register(registerRequired)}>
          <option value={""}>Select</option>
          {timeslot_group_options.map(({ value, label }) => <option value={value} key={value}>{label}</option>)}
        </select>
      </Control>

    </Card>
  );
}


const getValidationOptsByValueType = (valueType) => {
  const common = registerRequired;
  switch (valueType) {
    case 'percent':
      return {
        ...common,
        min: {value: -100, message: "must be greater or equal to -100"}, 
        max: {value: 9999.99, message: "must be less than 10000"}, 
      }
    case 'fixed':
      return {
        ...common,
       }
    case 'price':
      return {
        ...common,
        min: {value: 0, message: "must be greater or equal to 0"}, 
      }
  }
}


const ProductSpecificCard = ({ discount_rule_id, control, valueType, valueTypeLabel, valueTypeDescription, register, errors, product_options, branch, calcProductPrice, onProductValue, discountRuleProductsFieldArray }) => {



  const { fields, append, remove } = discountRuleProductsFieldArray;

  return (
    <Card title={"Product specific " + valueTypeLabel}>
      <div className="form-text text-muted text-small mb-2">
        <small>{valueTypeDescription}</small>
      </div>

      {product_options
        .map(product_option => {
          const calculatedPrice = calcProductPrice(product_option);
          const itemIndex = fields.findIndex(({ product_id }) => product_id == product_option.id);

          if (itemIndex >= 0) {
            // enabled
            const item = fields[itemIndex];

            const title = (
              <span className="custom-control custom-checkbox">
                <input className="custom-control-input"
                  type="checkbox"
                  id={`discount_rule_product_enabled_${product_option.id}`}
                  checked={true}
                  onChange={e => {
                    remove(itemIndex)
                  }}
                />
                <label className="custom-control-label" htmlFor={`discount_rule_product_enabled_${product_option.id}`}>{product_option.name + ", " + product_option.price + " " + branch.config.currency_symbol}</label>
              </span>
            );

            const inputValidationOptions = getValidationOptsByValueType(valueType)

            return (
              <Control title={title}
                error={
                  errors.discount_rule_products && errors.discount_rule_products[itemIndex] && errors.discount_rule_products[itemIndex].value && errors.discount_rule_products[itemIndex].value.message                    
                }
                key={item._id}>
                <input type="hidden" name={`discount_rule_products[${itemIndex}].id`} value={item.id} ref={register()} />
                <input type="hidden" name={`discount_rule_products[${itemIndex}].product_id`} value={item.product_id} ref={register()} />
                <input type="hidden" name={`discount_rule_products[${itemIndex}].discount_rule_id`} value={item.discount_rule_id} ref={register()} />
                <input className="form-control"
                  name={`discount_rule_products[${itemIndex}].value`}
                  type="number"
                  step="0.01"
                  ref={register(inputValidationOptions)}
                  placeholder={valueTypeLabel}
                  defaultValue={item.value}
                  onChange={e => onProductValue(product_option, e.target.value)} />

                <span className="input-group-append"><span className="discount-rule-calculator--product-price input-group-text">

                  {calculatedPrice !== null &&
                    (calculatedPrice + " " + branch.config.currency_symbol)
                  }
                  {calculatedPrice === null &&
                    (roundPrice(parseFloat(product_option.price)) + " " + branch.config.currency_symbol)
                  }
                  
                </span></span>
              </Control>
            );
          } else {
            // disabled 
            const title = (
              <span className="custom-control custom-checkbox">
                <input className="custom-control-input"
                  type="checkbox"
                  id={`discount_rule_product_enabled_${product_option.id}`}
                  onChange={e => {
                      append({
                        product_id: product_option.id,
                        discount_rule_id: discount_rule_id,
                      })
                    }}
                />
                <label className="custom-control-label" htmlFor={`discount_rule_product_enabled_${product_option.id}`}>{product_option.name + ", " + product_option.price + " " + branch.config.currency_symbol}</label>
              </span>
            );
            return (
              <Control title={title}
                key={product_option.id}>
                <input className="form-control"
                  type="number"
                  placeholder={valueTypeLabel}
                  disabled />
                <span className="input-group-append"><span className="discount-rule-calculator--product-price input-group-text">
                  {calculatedPrice !== null &&
                    calculatedPrice
                  }
                  {calculatedPrice === null &&
                    roundPrice(parseFloat(product_option.price))
                  }
                  &nbsp;{branch.config.currency_symbol}
                </span></span>
              </Control>
            );
          }
        })
      }

    </Card>
  )
}

function DiscountRuleForm(props) {
  const [discountRule, setDiscountRule] = useState({});
  const [productValues, setProductValues] = useState({});
  
  const { register, unregister, handleSubmit, watch, reset, setValue, getValues, control, errors, setError } = useForm({ 
    mode: 'onBlur' 
  });

  const discountRuleProductsFieldArray = useFieldArray({
    control,
    name: "discount_rule_products",
    keyName: "_id",
  });
  const { fields: discountRuleProductFields } = discountRuleProductsFieldArray;


  const branch = JSON.parse(props.branch);
  const { timeslot_group_options, value_type_options, repeat_options, status_options, product_options_by_timeslot_group_id } = JSON.parse(props.options);

  const onSubmit = async data => {

    const newData = {
      ...data,
      restricted: (data.restricted || "0") ,
      discount_date: formatDate(data.discount_date),
      repeat_until: 'repeat_until' in data ? formatDate(data.repeat_until) : null,
      // discount_time_since: formatTime(data.discount_time_since),
      // discount_time_until: formatTime(data.discount_time_until),
    }
    const method = "POST";
    try {
      const params = {
        _method: (discountRule.id) ? "put" : null,
        _csrf_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        discount_rule: newData,
        id: discountRule.id,
      };
      const formData = convertModelToFormData(params);
      const url = discountRule.id ? `/discount_rules/${discountRule.id}` : `/discount_rules`;


      const request = {
        url,
        method: method,
        headers: {
          'Content-Type' : 'application/x-www-form-urlencoded',
        },
        data: formData,
      };
      const response = await axiosInstance.request(request);
      // const response = await axiosInstance.put(`/discount_rules/${discountRule.id}`, params)
      location = `/discount_rules/${response.data.id}`
    } catch (err) {
      if (err.response) {

        const data = err.response.data;
        if (data.errors) {
          for (let key in data.errors) {
            setError(
              key, 
              {
                type: "manual",
                message: data.errors[key]
              })
          }
        }
      }      
    }
  };



  useEffect(async () => {
    if (props.discountRule) {
      setDiscountRule(JSON.parse(props.discountRule));
    }
  }, []);

  useEffect(async () => {
    reset(discountToFormData(discountRule));
  }, [discountRule]);


  const repeat = watch("repeat");
  const discountDate = watch("discount_date");
  const restricted = watch("restricted");
  const valueType = watch("value_type");
  const timeslot_group_id = watch("timeslot_group_id");
  const discount_rule_products = watch("discount_rule_products");



  const product_options = timeslot_group_id in product_options_by_timeslot_group_id ? product_options_by_timeslot_group_id[timeslot_group_id] : null;


  const setProductValue = (product_option, value) => {
    const product_id = "" + product_option.id;
    setProductValues({
      ...productValues,
      [product_id]: parsePercent(value),
    })
  }

  const calcProductPrice = (product_option) => {
    const value = productValues["" + product_option.id];
    return calcDiscountedPrice(product_option.price, valueType, value)
  }


  const getDiscountRuleProductValuesFromFields = () => {
    return product_options.reduce((acc, {id}) => {
      const index = discountRuleProductFields.findIndex( ({product_id}) => product_id == id);
      if (index>=0) {
        const value = getValues(`discount_rule_products[${index}].value`)
        return {
          ...acc,
          [id]: parsePercent(value),
        }
      } else {
        return acc;
      }
    }, {})
  }

  const resetDiscountRuleProductValues = () => {
    for (let product_option of product_options) {
      const index = discountRuleProductFields.findIndex( ({product_id}) => product_id == product_option.id);
      if (index>=0) {
        setValue(`discount_rule_products[${index}].value`, null)
      }
    }
  }

  const getNullDiscountRuleProductValues = () => {
    return product_options.reduce((acc, {id}) => {
      const index = discountRuleProductFields.findIndex( ({product_id}) => product_id == id);
      if (index>=0) {
        return {
          ...acc,
          [id]: null,
        }
      } else {
        return acc;
      }
    }, {})
  }


  useEffect(() => {
    equalWidth.resizeElements(true) 
  }, [repeat, restricted, valueType, timeslot_group_id, discount_rule_products])


  const isFirstRender = useRef(true);
  useEffect(() => {
    if (product_options!=null) {
      if (isFirstRender.current) {

        // causes recalculation of the discounted product prices
        setProductValues(getDiscountRuleProductValuesFromFields());

        isFirstRender.current = false;
        return;
      }

      // sets discount value fields to empty
      // setProductValues(getNullDiscountRuleProductValues());

      
      resetDiscountRuleProductValues();

      // for (let product_option of product_options) {
      //   const index = discountRuleProductFields.findIndex( ({product_id}) => product_id == product_option.id);
      //   if (index>=0) {
      //     unregister(`discount_rule_products[${index}].value`);
      //     register(`discount_rule_products[${index}].value`, getValidationOptsByValueType(valueType));
      //   }
      // }

      setProductValues(getDiscountRuleProductValuesFromFields());
    } 
    
    if (restricted) {

      unregister(`value`);
      if (product_options!=null) {
        for (let product_option of product_options) {
          const index = discountRuleProductFields.findIndex( ({product_id}) => product_id == product_option.id);
          if (index>=0) {
            unregister(`discount_rule_products[${index}].value`);
            register(`discount_rule_products[${index}].value`, getValidationOptsByValueType(valueType));
          }
        }
      }

    } else {

      if (product_options!=null) {
        for (let product_option of product_options) {
          const index = discountRuleProductFields.findIndex( ({product_id}) => product_id == product_option.id);
          if (index>=0) {
            unregister(`discount_rule_products[${index}].value`);
          }
        }
      }

      unregister(`value`);
      register(`value`, getValidationOptsByValueType(valueType));
    }

  }, [valueType])



  const valueTypeLabel = (value_type_options.find(({ value }) => value == valueType) || {}).label;

  function getValueTypeDescription(valueType) {
    switch (valueType) {
      case 'percent': return "Use negative value to apply a discount (-10) or positive to add a surcharge (5)";
      case 'price': return "Type in the exact product price in " + branch.config.currency;
      case 'fixed': return "Exact discount value in " + branch.config.currency;
    }
  }

  const valueTypeDescription = getValueTypeDescription(valueType);

  return (
    <div>
      { discountRule &&
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TimeslotGroupCard errors={errors} register={register} timeslot_group_options={timeslot_group_options} />

          <DiscountScheduleCard control={control} repeat={repeat} discountDate={discountDate} errors={errors} register={register} repeat_options={repeat_options} />

          <DiscountDetailsCard control={control} register={register} repeat={repeat} errors={errors} value_type_options={value_type_options} />

          {!restricted && valueType &&
            <DiscountValueCard register={register} restricted={restricted} 
              valueType={valueType} valueTypeLabel={valueTypeLabel} valueTypeDescription={valueTypeDescription} 
              errors={errors} branch={branch} />
          }

          {restricted && valueType && timeslot_group_id &&
            <ProductSpecificCard
              control={control}
              valueType={valueType}
              valueTypeLabel={valueTypeLabel} valueTypeDescription={valueTypeDescription} 
              discount_rule_id={discountRule.id}
              register={register} errors={errors}
              product_options={product_options} branch={branch}
              calcProductPrice={calcProductPrice} onProductValue={setProductValue} 
              discountRuleProductsFieldArray={discountRuleProductsFieldArray}/>
          }

          <AdditionalDetailsCard register={register} errors={errors} status_options={status_options} />

          <FormActions />
        </Form>
      }
    </div>
  )

  function discountToFormData(discountRule) {
    return {
      ...discountRule,
      discount_date: discountRule.discount_date ? new Date(discountRule.discount_date) : null,
      discount_time_since: discountRule.discount_time_since ? formatTime(parseTime(discountRule.discount_time_since)) : null,
      discount_time_until: discountRule.discount_time_until ? formatTime(parseTime(discountRule.discount_time_until)) : null,
      repeat_until: discountRule.repeat_until ? new Date(discountRule.repeat_until) : null,
    };
  }

}

export default DiscountRuleForm;

