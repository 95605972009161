import {apiRequest} from "../lib/api";

export default class TimeslotGroupApi {

  static async get(id) {
    const response = await apiRequest({
      url: "/graph",
      method: "POST",
      data: {
        "operationName": "getTimeslotGroup",
        "query": `      
        query getTimeslotGroup($timeslotGroupId: Int!) {
          timeslotGroup(id: $timeslotGroupId){
            id
            name
            timeslots {
              id
              valid
              timeslotDate
              timeslotStart
              timeslotEnd
              leftUsages
              datetime
            }
          }
        }
      `,
        "variables": {
          'timeslotGroupId': id,
        }
      },
    })
    return response.data.data && response.data.data.timeslotGroup;
  }

}