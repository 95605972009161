import React, {useEffect, useState} from "react";
import PurchaseApi from "../../api/PurchaseApi";
import Purchase from "../../components/purchase/Purchase";


const InlinePurchaseApp = (props) => {


  const [purchase, setPurchase] = useState();
  const [editMode, setEditMode] = useState(props.editMode || false);

  const [loading, setLoading] = useState(false);
  const [changes, setChanges] = useState({});
  const [sendTickets, setSendTickets] = useState(true);
  const [message, setMessage] = useState(null);
  const [version, setVersion] = useState(0);


  useEffect(async () => {
    setPurchase(await PurchaseApi.get(props.purchaseId));
  }, [])


  const onChangeLineItem = (lineItemId, lineItemChanges) => {
    setChanges({
      ...changes,
      [lineItemId]: lineItemChanges,
    })
  }

  const hasChanges = () => {
    return Object.keys(changes).length > 0;
  }

  const isChangeValid = (change) => {
    return change.timeslot.id != null;
  }

  const isValid = () => {
    return Object.entries(changes).every(([id, change]) => isChangeValid(change));
  }

  const currentLineItems = () => {
    return purchase.lineItems.map(li => {
      if (li.id in changes) {
        return {
          ...li,
          ...changes[li.id],
        }
      } else {
        return li;
      }
    })
  }

  const getCurrentPurchase = () => {
    if (!purchase) {
      return purchase;
    }
    return {
      ...purchase,
      lineItems: currentLineItems(),
    }
  }


  function buildLineItemPayload(rawChange) {
    return {
      timeslotId: rawChange.timeslot.id,
    }
  }

  function buildUpdatePayload() {
    let lineItemsChanges = purchase.lineItems.map((lineItem) => {
      let lineItemChange = {};
      if (lineItem.id in changes) {
        let rawChange = changes[lineItem.id];
        lineItemChange = buildLineItemPayload(rawChange);
      }
      return {
        id: lineItem.id,
        ...lineItemChange,
      }
    });
    return {
      lineItems: lineItemsChanges,
      sendTickets,
    };
  }

  const onCancel = () => {
    setChanges({});
    setEditMode(false)
    setMessage(null);
  }

  const onSetEditMode = (mode) => {
    setMessage(null)
    setEditMode(mode)
  }

  function renderSuccessMessage() {
    if (sendTickets) {
      return <div className="text-success">Purchase updated and tickets sent <i className="fas fa-check"></i></div>;
    } else {
      return <div className="text-success">Purchase updated <i className="fas fa-check"></i></div>;
    }
  }

  const onSave = async () => {
    if (hasChanges()) {
      try {
        setMessage(null);
        setLoading(true);
        const updatedPurchase = await PurchaseApi.update(purchase.id, buildUpdatePayload());
        setLoading(false);
        setChanges({});
        setPurchase(updatedPurchase);
        setEditMode(false);
        setMessage(renderSuccessMessage());
        setVersion(version + 1);
      } catch (e) {
        setMessage(<div className="text-danger">{e.message}</div>)
        setLoading(false);
      }
    }
  }

  const currentPurchase = getCurrentPurchase();

  return <div>
    {currentPurchase &&
      <Purchase purchase={currentPurchase}
                onSave={onSave}
                onCancel={onCancel}
                onChangeLineItem={onChangeLineItem}
                hasChanges={hasChanges()}
                editMode={editMode}
                onEditMode={(mode) => onSetEditMode(mode)}
                loading={loading}
                valid={isValid()}
                sendTickets={sendTickets}
                onSendTicketsChange={setSendTickets}
                message={message}
                version={version}
      />
    }
    {!currentPurchase &&
      <div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    }
  </div>;
};

export default InlinePurchaseApp;