import React, {Component, useEffect, useState} from "react";

const FormActions = (props) => {

  const goBack = () => history.back()
  
  return (
    <div className="form-group row justify-content-end">
			<div className="col text-left">
				<a onClick={goBack} className="btn btn-default">Cancel</a>
			</div>
			<div className="col text-right">
        <button className="btn btn-primary" type="submit">Save</button>			
      </div>
		</div>
  )
}

export default FormActions;